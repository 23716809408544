import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chartColors } from 'utilities/transform';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  LineSeries,
  LabelSeries,
  VerticalGridLines,
  HorizontalGridLines
} from 'react-vis';

const TimeSeriesLineChart = ({ className, data }) => {
  const classes = classNames(className || 'timeseries-chart');

  const chartHeight = 360;
  const [maxYDomain, setMaxYDomain] = useState(8);

  const yValues = data.map(item => {
    return item.map(arr => arr.y);
  });
  const maxYValues = yValues.map(item => {
    const maxValues = item.reduce(function(a, b) {
      return Math.max(a, b);
    });

    return maxValues;
  });

  useEffect(() => {
    const currentMaxYDomain = Math.max(...maxYValues);

    if (maxYDomain - 10 <= currentMaxYDomain && currentMaxYDomain > 6) {
      setMaxYDomain(currentMaxYDomain * 1.5);
    }
  }, [maxYValues, maxYDomain]);

  const tickFormat = useCallback(v => new Date(+v).toLocaleTimeString(), []);

  return (
    <div className={classes}>
      <FlexibleWidthXYPlot
        colorType="literal"
        height={chartHeight}
        yDomain={[0, maxYDomain]}
        margin={{ left: maxYDomain > 1000 ? 70 : 40, right: 80 }}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          attr="x"
          attrAxis="y"
          tickFormat={tickFormat}
          tickTotal={6}
          orientation="bottom"
        />
        <YAxis tickTotal={5} />
        {data.map((item, index) => [
          <LineSeries
            key={`${item.x}-line`}
            data={item}
            color={chartColors[index % chartColors.length]}
            strokeWidth="2"
            curve={'curveMonotoneX'}
          />
        ])}
        {data.map(item => [
          <LabelSeries
            key={`${item.x}-label`}
            data={item}
            labelAnchorX="left"
            labelAnchorY="text-after-edge"
          />
        ])}
      </FlexibleWidthXYPlot>
      <div className="legend-wrapper">
        <div className="label">Legend</div>
        <ul className="legend-list">
          {data.map((item, index) => (
            <li className="legend-item" key={item[0].label}>
              <span
                style={{
                  backgroundColor: chartColors[index % chartColors.length]
                }}
              />
              <span>{item[0].label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

TimeSeriesLineChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        y: PropTypes.number,
        x: PropTypes.number,
        color: PropTypes.string
      })
    )
  )
};

export default TimeSeriesLineChart;
