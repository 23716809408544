// router
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
// persistentItems
export const INIT_PERSISTENT_ITEMS = 'INIT_PERSISTENT_ITEMS';

export const FETCH_PERSISTENT_ITEMS_START = 'FETCH_PERSISTENT_ITEMS_START';
export const FETCH_PERSISTENT_ITEMS_ERROR = 'FETCH_PERSISTENT_ITEMS_ERROR';
export const FETCH_PERSISTENT_ITEMS_SUCCESS = 'FETCH_PERSISTENT_ITEMS_SUCCESS';

export const CLEAR_PERSISTENT_ITEMS = 'CLEAR_PERSISTENT_ITEMS';

// Flow editor
export const HIDE_STENCIL = 'HIDE_STENCIL';
export const SHOW_STENCIL = 'SHOW_STENCIL';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';

// AUTH
export const AUTH_CHECK_REQUESTED = 'AUTH_CHECK_REQUESTED';
export const AUTH_CHECK_FAILED = 'AUTH_CHECK_FAILED';
export const AUTH_CHECK_SUCCEEDED = 'AUTH_CHECK_SUCCEEDED';

export const AUTH_SET_PASSWORD_REQUESTED = 'AUTH_SET_PASSWORD_REQUESTED';
export const AUTH_SET_PASSWORD_SUCCEEDED = 'AUTH_SET_PASSWORD_SUCCEEDED';
export const AUTH_SET_PASSWORD_FAILED = 'AUTH_SET_PASSWORD_FAILED';

// Flow API
export const FETCH_FLOW_SUCCESS = 'FETCH_FLOW_SUCCESS';
export const FETCH_FLOWS_SUCCESS = 'FETCH_FLOWS_SUCCESS';

export const FETCH_NODE_TYPE_CONNECTORS_SUCCESS =
  'FETCH_NODE_TYPE_CONNECTORS_SUCCESS';
export const FETCH_NODE_TYPE_PARAMETERS_SUCCESS =
  'FETCH_NODE_TYPE_PARAMETERS_SUCCESS';

// Users API
export const INIT_USER = 'INIT_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const UPSERT_USER_START = 'UPSERT_USER_START';
export const UPSERT_USER_SUCCESS = 'UPSERT_USER_SUCCESS';
export const UPSERT_USER_END = 'UPSERT_USER_END';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const UPSERT_CUSTOMER_START = 'UPSERT_CUSTOMER_START';
export const UPSERT_CUSTOMER_END = 'UPSERT_CUSTOMER_END';
export const UPSERT_CUSTOMER_SUCCESS = 'UPSERT_CUSTOMER_SUCCESS';

// Modal dialogs
export const CREATE_MODAL = 'CREATE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// Panels
export const RIGHT_PANEL_OPEN = 'RIGHT_PANEL_OPEN';
export const RIGHT_PANEL_CLOSE = 'RIGHT_PANEL_CLOSE';

// Kpis
export const RESET_KPI = 'RESET_KPI';

export const GET_FLOW_KPI_REQUESTED = 'GET_FLOW_KPI_REQUESTED';
export const GET_FLOW_KPI_SUCCEEDED = 'GET_FLOW_KPI_SUCCEEDED';
export const GET_FLOW_KPI_FAILED = 'GET_FLOW_KPI_FAILED';

// Persistent state
export const ADD_PERSISTENT_STATE_ITEM_REQUESTED =
  'ADD_PERSISTENT_STATE_ITEM_REQUESTED';
export const ADD_PERSISTENT_STATE_ITEM_SUCCEEDED =
  'ADD_PERSISTENT_STATE_ITEM_SUCCEEDED';
export const ADD_PERSISTENT_STATE_ITEM_FAILED =
  'ADD_PERSISTENT_STATE_ITEM_FAILED';

export const LOAD_PERSISTENT_STATE_REQUESTED =
  'LOAD_PERSISTENT_STATE_REQUESTED';
export const LOAD_PERSISTENT_STATE_SUCCEEDED =
  'LOAD_PERSISTENT_STATE_SUCCEEDED';
export const LOAD_PERSISTENT_STATE_FAILED = 'LOAD_PERSISTENT_STATE_FAILED';

export const CLEAR_PERSISTENT_STATE_ITEM_REQUESTED =
  'CLEAR_PERSISTENT_STATE_ITEM_REQUESTED';
export const CLEAR_PERSISTENT_STATE_ITEM_SUCCEEDED =
  'CLEAR_PERSISTENT_STATE_ITEM_SUCCEEDED';
export const CLEAR_PERSISTENT_STATE_ITEM_FAILED =
  'CLEAR_PERSISTENT_STATE_ITEM_FAILED';

export const REMOVE_PERSISTENT_STATE_ITEMS_REQUESTED =
  'REMOVE_PERSISTENT_STATE_ITEMS_REQUESTED';
export const REMOVE_PERSISTENT_STATE_ITEMS_SUCCEEDED =
  'REMOVE_PERSISTENT_STATE_ITEMS_SUCCEEDED';
export const REMOVE_PERSISTENT_STATE_ITEMS_FAILED =
  'REMOVE_PERSISTENT_STATE_ITEMS_FAILED';

// Notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

// Loaders
export const SET_LOADER = 'SET_LOADER';
export const SET_LOADER_REQUESTED = 'SET_LOADER_REQUESTED';
export const LOADERS_AUTH_ACTIVATE = 'LOADERS_AUTH_ACTIVATE';
export const LOADERS_AUTH_DEACTIVATE = 'LOADERS_AUTH_DEACTIVATE';

// Users/Customers
export const USERS_INVITE_REQUESTED = 'USERS_INVITE_REQUESTED';
export const USER_INVITE_SUCCEEDED = 'USER_INVITE_SUCCEEDED';
export const USER_INVITE_FAILED = 'USER_INVITE_FAILED';

export const AUTHORIZE_USER_REQUESTED = 'AUTHORIZE_USER_REQUESTED';
export const AUTHORIZE_USER_FAILED = 'AUTHORIZE_USER_FAILED';

export const LOG_IN_USER_REQUESTED = 'LOG_IN_USER_REQUESTED';
export const LOG_IN_USER_SUCCEEDED = 'LOG_IN_USER_SUCCEEDED';
export const LOG_IN_USER_FAILED = 'LOG_IN_USER_FAILED';

export const LOG_OUT_USER_REQUESTED = 'LOG_OUT_USER_REQUESTED';
export const LOG_OUT_USER_SUCCEEDED = 'LOG_OUT_USER_SUCCEEDED';
export const LOG_OUT_USER_FAILED = 'LOG_OUT_USER_FAILED';

export const REGISTER_USER_REQUESTED = 'REGISTER_USER_REQUESTED';

export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const UPDATE_CUSTOMER_DETAILS_REQUESTED =
  'UPDATE_CUSTOMER_DETAILS_REQUESTED';
export const UPDATE_CUSTOMER_DETAILS_FAILED = 'UPDATE_CUSTOMER_DETAILS_FAILED';
export const UPDATE_CUSTOMER_DETAILS_SUCCEEDED =
  'UPDATE_CUSTOMER_DETAILS_SUCCEEDED';

export const GET_CUSTOMER_DETAILS_REQUESTED = 'GET_CUSTOMER_DETAILS_REQUESTED';
export const GET_CUSTOMER_DETAILS_SUCCEEDED = 'GET_CUSTOMER_DETAILS_SUCCEEDED';
export const GET_CUSTOMER_DETAILS_FAILED = 'GET_CUSTOMER_DETAILS_FAILED';

export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_SUCCEEDED = 'GET_USERS_SUCCEEDED';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_SUCCEEDED = 'DELETE_USER_SUCCEEDED';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

/* DATA NODES/SOURCES */

export const FETCH_DATANODES_REQUESTED = 'FETCH_DATANODES_REQUESTED';
export const FETCH_DATANODES_SUCCEEDED = 'FETCH_DATANODES_SUCCEEDED';
export const FETCH_DATANODES_FAILED = 'FETCH_DATANODES_FAILED';

export const CREATE_DATANODE_REQUESTED = 'CREATE_DATANODE_REQUESTED';
export const CREATE_DATANODE_SUCCEEDED = 'CREATE_DATANODE_SUCCEEDED';
export const CREATE_DATANODE_FAILED = 'CREATE_DATANODE_FAILED';

export const DELETE_DATANODE_REQUESTED = 'DELETE_DATANODE_REQUESTED';
export const DELETE_DATANODE_SUCCEEDED = 'DELETE_DATANODE_SUCCEEDED';
export const DELETE_DATANODE_FAILED = 'DELETE_DATANODE_FAILED';

export const FETCH_DATATYPES_REQUESTED = 'FETCH_DATATYPES_REQUESTED';
export const FETCH_DATATYPES_SUCCEEDED = 'FETCH_DATATYPES_SUCCEEDED';
export const FETCH_DATATYPES_FAILED = 'FETCH_DATATYPES_FAILED';

export const FETCH_DATASOURCES_REQUESTED = 'FETCH_DATASOURCES_REQUESTED';
export const FETCH_DATASOURCES_SUCCEEDED = 'FETCH_DATASOURCES_SUCCEEDED';
export const FETCH_DATASOURCES_FAILED = 'FETCH_DATASOURCES_FAILED';

export const FETCH_DATASOURCES_BY_DATANODE_ID_REQUESTED =
  'FETCH_DATASOURCES_BY_DATANODE_ID_REQUESTED';
export const FETCH_DATASOURCES_BY_DATANODE_ID_SUCCEEDED =
  'FETCH_DATASOURCES_BY_DATANODE_ID_SUCCEEDED';
export const FETCH_DATASOURCES_BY_DATANODE_ID_FAILED =
  'FETCH_DATASOURCES_BY_DATANODE_ID_FAILED';

export const UPDATE_DATASOURCES_REQUESTED = 'UPDATE_DATASOURCES_REQUESTED';
export const UPDATE_DATASOURCES_SUCCEEDED = 'UPDATE_DATASOURCES_SUCCEEDED';
export const UPDATE_DATASOURCES_FAILED = 'UPDATE_DATASOURCES_FAILED';

export const UPLOAD_DATASOURCES_CSV_REQUESTED =
  'UPLOAD_DATASOURCES_CSV_REQUESTED';
export const UPLOAD_DATASOURCES_CSV_SUCCEEDED =
  'UPLOAD_DATASOURCES_CSV_SUCCEEDED';
export const UPLOAD_DATASOURCES_CSV_FAILED = 'UPLOAD_DATASOURCES_CSV_FAILED';

export const UNASSIGN_DATASOURCES_REQUESTED = 'UNASSIGN_DATASOURCES_REQUESTED';
export const UNASSIGN_DATASOURCES_SUCCEEDED = 'UNASSIGN_DATASOURCES_SUCCEEDED';
export const UNASSIGN_DATASOURCES_FAILED = 'UNASSIGN_DATASOURCES_FAILED';

export const DELETE_DATASOURCE_REQUESTED = 'DELETE_DATASOURCE_REQUESTED';
export const DELETE_DATASOURCE_SUCCEEDED = 'DELETE_DATASOURCE_SUCCEEDED';
export const DELETE_DATASOURCE_FAILED = 'DELETE_DATASOURCE_FAILED';

export const FETCH_UNASSIGNED_DATASOURCES_REQUESTED =
  'FETCH_UNASSIGNED_DATASOURCES_REQUESTED';
export const FETCH_UNASSIGNED_DATASOURCES_SUCCEEDED =
  'FETCH_UNASSIGNED_DATASOURCES_SUCCEEDED';
export const FETCH_UNASSIGNED_DATASOURCES_FAILED =
  'FETCH_UNASSIGNED_DATASOURCES_FAILED';
export const FETCH_DATASOURCES_BY_DATANODE_ID_SUCCESS =
  'FETCH_DATASOURCES_BY_DATANODE_ID_SUCCESS';

/* FLOWS */
export const CLEAR_FLOW_DATA = 'CLEAR_FLOW_DATA';

export const CREATE_FLOW_REQUESTED = 'CREATE_FLOW_REQUESTED';
export const CREATE_FLOW_SUCCEEDED = 'CREATE_FLOW_SUCCEEDED';
export const CREATE_FLOW_FAILED = 'CREATE_FLOW_FAILED';

export const UPDATE_FLOW_REQUESTED = 'UPDATE_FLOW_REQUESTED';
export const UPDATE_FLOW_SUCCEEDED = 'UPDATE_FLOW_SUCCEEDED';
export const UPDATE_FLOW_FAILED = 'UPDATE_FLOW_FAILED';

export const DELETE_FLOW_REQUESTED = 'DELETE_FLOW_REQUESTED';
export const DELETE_FLOW_SUCCEEDED = 'DELETE_FLOW_SUCCEEDED';
export const DELETE_FLOW_FAILED = 'DELETE_FLOW_FAILED';

export const UPDATE_FLOW_NODES_REQUESTED = 'UPDATE_FLOW_NODES_REQUESTED';
export const UPDATE_FLOW_NODES_SUCCEEDED = 'UPDATE_FLOW_NODES_SUCCEEDED';
export const UPDATE_FLOW_NODES_FAILED = 'UPDATE_FLOW_NODES_FAILED';

export const FETCH_NODE_DEFINITIONS_REQUESTED =
  'FETCH_NODE_DEFINITIONS_REQUESTED';
export const FETCH_NODE_DEFINITIONS_SUCCEEDED =
  'FETCH_NODE_DEFINITIONS_SUCCEEDED';
export const FETCH_NODE_DEFINITIONS_FAILED = 'FETCH_NODE_DEFINITIONS_FAILED';

export const CLONE_FLOW_REQUESTED = 'CLONE_FLOW_REQUESTED';
export const CLONE_FLOW_SUCCEEDED = 'CLONE_FLOW_SUCCEEDED';
export const CLONE_FLOW_FAILED = 'CLONE_FLOW_FAILED';

export const DEPLOY_FLOW_REQUESTED = 'DEPLOY_FLOW_REQUESTED';
export const DEPLOY_FLOW_SUCCEEDED = 'DEPLOY_FLOW_SUCCEEDED';
export const DEPLOY_FLOW_FAILED = 'DEPLOY_FLOW_FAILED';

export const DESTROY_FLOW_REQUESTED = 'DESTROY_FLOW_REQUESTED';
export const DESTROY_FLOW_SUCCEEDED = 'DESTROY_FLOW_SUCCEEDED';
export const DESTROY_FLOW_FAILED = 'DESTROY_FLOW_FAILED';

export const REDEPLOY_FLOW_NODE_REQUESTED = 'REDEPLOY_FLOW_NODE_REQUESTED';
export const REDEPLOY_FLOW_NODE_SUCCEEDED = 'REDEPLOY_FLOW_NODE_SUCCEEDED';
export const REDEPLOY_FLOW_NODE_FAILED = 'REDEPLOY_FLOW_NODE_FAILED';

/* Integrations */

export const CREATE_INTEGRATION_CREDENTIALS_REQUESTED =
  'CREATE_INTEGRATION_CREDENTIALS_REQUESTED';
export const CREATE_INTEGRATION_CREDENTIALS_SUCCEEDED =
  'CREATE_INTEGRATION_CREDENTIALS_SUCCEEDED';
export const CREATE_INTEGRATION_CREDENTIALS_FAILED =
  'CREATE_INTEGRATION_CREDENTIALS_FAILED';

export const DELETE_INTEGRATION_CREDENTIALS_REQUESTED =
  'DELETE_INTEGRATION_CREDENTIALS_REQUESTED';
export const DELETE_INTEGRATION_CREDENTIALS_SUCCEEDED =
  'DELETE_INTEGRATION_CREDENTIALS_SUCCEEDED';
export const DELETE_INTEGRATION_CREDENTIALS_FAILED =
  'DELETE_INTEGRATION_CREDENTIALS_FAILED';

export const UPDATE_INTEGRATION_CREDENTIALS_REQUESTED =
  'UPDATE_INTEGRATION_CREDENTIALS_REQUESTED';
export const UPDATE_INTEGRATION_CREDENTIALS_SUCCEEDED =
  'UPDATE_INTEGRATION_CREDENTIALS_SUCCEEDED';
export const UPDATE_INTEGRATION_CREDENTIALS_FAILED =
  'UPDATE_INTEGRATION_CREDENTIALS_FAILED';

// Feature flags
export const BATCH_EVALUATE_FEATURE_FLAGS_REQUESTED =
  'BATCH_EVALUATE_FEATURE_FLAGS_REQUESTED';
export const BATCH_EVALUATE_FEATURE_FLAGS_SUCCEEDED =
  'BATCH_EVALUATE_FEATURE_FLAGS_SUCCEEDED';
export const BATCH_EVALUATE_FEATURE_FLAGS_FAILED =
  'BATCH_EVALUATE_FEATURE_FLAGS_FAILED';
export const EVALUATE_FEATURE_FLAG_REQUESTED =
  'EVALUATE_FEATURE_FLAG_REQUESTED';
export const EVALUATE_FEATURE_FLAG_SUCCEEDED =
  'EVALUATE_FEATURE_FLAG_SUCCEEDED';
export const EVALUATE_FEATURE_FLAG_FAILED = 'EVALUATE_FEATURE_FLAG_FAILED';
