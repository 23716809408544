import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chartColors } from 'utilities/transform';
import { NoData, WidgetWrapper } from 'components';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines
} from 'react-vis';

const LineChart = ({ className, data, title, name }) => {
  const classes = classNames(className || 'line-chart');

  const items = data?.items ?? [];
  const chartHeight = 400;
  const domainMin = 0;

  const [maxYDomain, setMaxYDomain] = useState(20);

  const chart = items.map(
    item =>
      (data = {
        x: item?.key,
        y: item?.value
      })
  );

  useEffect(() => {
    const currentMaxYDomain = Math.max(...chart.map(({ y }) => (y ? y : 0)));

    if (currentMaxYDomain > 20) {
      setMaxYDomain(currentMaxYDomain * 1.5);
    } else {
      setMaxYDomain(20);
    }
  }, [chart, maxYDomain]);

  return (
    <WidgetWrapper title={title} name={name}>
      {chart.length > 0 ? (
        <div className={classes}>
          <FlexibleWidthXYPlot
            height={chartHeight}
            yDomain={[domainMin, maxYDomain]}
            margin={{ left: maxYDomain > 1000 ? 70 : 40 }}
            xType="ordinal"
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis attr="x" attrAxis="y" orientation="bottom" />
            <YAxis />
            <LineSeries
              data={chart}
              style={{ stroke: chartColors[1] }}
              strokeWidth="2"
            />
          </FlexibleWidthXYPlot>
        </div>
      ) : (
        <NoData />
      )}
    </WidgetWrapper>
  );
};

LineChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object // eslint-disable-line
};

export default LineChart;
