import React, { useCallback } from 'react';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Panels } from 'constants';
import { Toolbar } from 'components';
import { RightPanel } from 'containers';
import { requestUserLogout } from 'store/actions/auth';
import { openRightPanel, closeRightPanel } from 'store/actions/panels';

const Layout = ({
  children,
  authenticated,
  inProgress,
  onMount,
  email = ''
}) => {
  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(requestUserLogout()), [dispatch]);
  const panels = useSelector(state => state.panels);

  useMount(() => {
    if (onMount) {
      onMount();
    }
  });

  const handleOpenNotifications = useCallback(() => {
    if (panels.visible) {
      dispatch(closeRightPanel());
    } else {
      dispatch(
        openRightPanel({
          panel: Panels.NOTIFICATIONS_PANEL
        })
      );
    }
  }, [panels.visible, dispatch]);

  const content = (
    <div id="content">
      <Toolbar
        authenticated={authenticated}
        onLogout={logout}
        openNotifications={handleOpenNotifications}
        email={email}
      />
      {children}
      {panels.visible ? <RightPanel /> : null}
    </div>
  );

  return authenticated || inProgress ? content : <Redirect to="/" />;
};

Layout.propTypes = {
  authenticated: PropTypes.bool
};

export default withRouter(Layout);
