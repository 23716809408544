import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { decode } from 'html-entities';

import { flowsSelector } from 'store/selectors';

import { Icon, Button, KeyStat, InfoIcon } from 'components';
import FlowTable from 'components/Flows/FlowBrowser/FlowTable/FlowTable';
import { FlowStatus, FlowContainerTabs } from 'constants/flow';
import ClickOutside from 'hoc/ClickOutside/ClickOutside';

import useDropdown from 'hooks/useDropdown';
import { isEmpty, flowsTableData } from 'utilities';

const FlowToolbar = ({
  flow,
  status,
  openDeploy,
  openDelete,
  openClone,
  selectedTab,
  handleFlowSelect,
  graphChanged,
  openDestroy,
  keyStatItems
}) => {
  const flowFetched = useMemo(() => flow.fetched, [flow.fetched]);
  const flows = useSelector(flowsSelector, isEqual);
  const data = useMemo(() => flowsTableData(flows), [flows]);

  const isFlowEmpty = useMemo(
    () =>
      flowFetched ? isEmpty(flow.nodes) && isEmpty(flow.parameters) : false,
    [flow.nodes, flow.parameters, flowFetched]
  );

  const [toggle, setToggle] = useState(false);
  const { shouldShow, setShouldShow } = useDropdown();

  const toggleDropdown = useCallback(() => {
    setShouldShow(!shouldShow);
    setToggle(!shouldShow ? 'toggle' : '');
  }, [shouldShow, setShouldShow]);

  const handleSelect = useCallback(
    id => {
      handleFlowSelect(id);
      toggleDropdown();
    },
    [handleFlowSelect, toggleDropdown]
  );

  function getPrimaryButtonsByState(isDisabled) {
    if (flow.example) return null;

    if (
      flow.status === FlowStatus.Running ||
      flow.status === FlowStatus.Pending
    ) {
      return (
        <Button
          className="primary"
          id="flow-stop"
          onClick={openDestroy}
          label="Stop"
          disabled={isDisabled}
        />
      );
    } else {
      return (
        <Button
          className="primary"
          id="flow-run"
          type="button"
          onClick={openDeploy}
          label="Run"
          disabled={isDisabled}
        />
      );
    }
  }

  let configureButtons;

  switch (selectedTab) {
    case FlowContainerTabs.Configure:
      configureButtons = (
        <div className="statusbar-action-buttons">
          <Button className="primary" id="flow-save" label="Save" />
          {getPrimaryButtonsByState(graphChanged)}
          <Button
            className="icon outlined rename-flow-btn"
            disabled
            icon={['far', 'pencil']}
            data-tip="Rename"
          />
          <Button
            className="icon outlined danger delete-flow-btn"
            onClick={openDelete}
            icon={['far', 'trash-alt']}
            data-tip="Delete"
          />
          <Button
            className="primary"
            id="flow-clone"
            onClick={openClone}
            label="Clone"
          />
        </div>
      );
      break;
    case FlowContainerTabs.Analyze:
      if (flow.status) {
        configureButtons = getPrimaryButtonsByState(false);
      }
      break;
    default:
      configureButtons = null;
      break;
  }

  const columns = useMemo(
    () => generateColumns({ selectedTab, handleSelect }),
    [selectedTab, handleSelect]
  );

  const defaultSorted = [
    {
      id: 'name',
      desc: false
    }
  ];

  return (
    <div className="statusbar-container">
      <div className={classnames('statusbar', isFlowEmpty ? 'new' : status)}>
        {flow.name ? (
          <div className="statusbar-info" onClick={toggleDropdown}>
            <div className="statusbar-tags">
              <span
                className={classnames(
                  'flow-status',
                  'tag',
                  isFlowEmpty ? 'new' : status
                )}
              >
                {status}
              </span>
              {flow.description ? (
                <InfoIcon className="statusbar-info-icon" place="bottom">
                  {decode(flow.description, { level: 'all' })}
                </InfoIcon>
              ) : null}
            </div>

            <h2 className="statusbar-name">
              {decode(flow.name, { level: 'all' })}
            </h2>

            <Icon
              className={classnames('statusbar-caret', toggle)}
              name={['fas', 'caret-down']}
              onClick={toggleDropdown}
            />
          </div>
        ) : null}
        <div className="statusbar-stats-container">
          <div className="key-stat">
            <span className="key-stat-value">1.60</span>
            <span className="key-stat-legend">Memory (GB)</span>
          </div>
          {/* <div className="statusbar-status">
            <span>Downloading images...5/8</span>
          </div> */}
          {keyStatItems && status === 'running'
            ? keyStatItems.map(keyStat => <KeyStat {...keyStat} />)
            : null}
        </div>
        {configureButtons}
      </div>
      {shouldShow && (
        <div className="table-overlay">
          <ClickOutside onClickOutside={toggleDropdown}>
            <FlowTable
              className="flow-dropdown-table"
              columns={columns}
              data={data}
              pagination={false}
              isDropdown
              onClick={toggleDropdown}
              minRows={Object.values(FlowStatus).length}
              defaultSorted={defaultSorted}
            />
          </ClickOutside>
        </div>
      )}
    </div>
  );
};

const generateColumns = ({ handleSelect }) => [
  {
    Header: <span className="text">Flow</span>,
    accessor: 'name',
    className: 'flow-name text',
    Cell: row => {
      return (
        <div className="cell-actions-wrapper">
          <div
            className="cell-flow-name"
            onClick={() => handleSelect(row.original.ID)}
          >
            {decode(row.value, { level: 'all' })}
          </div>
        </div>
      );
    }
  },
  {
    Header: () => <div className="flow-status__filter">State</div>,
    className: 'flow-status',
    headerClassName: 'flow-status__header',
    accessor: 'status',
    width: 180,
    sortable: true,
    Cell: row => {
      if (row.value === FlowStatus.Stopped)
        return <span className={`flow-status tag orange`}>{row.value}</span>;
      if (row.value === FlowStatus.Running)
        return <span className={`flow-status tag green`}>{row.value}</span>;
      if (row.value === FlowStatus.Terminating)
        return <span className={`flow-status tag grey`}>{row.value}</span>;
      if (row.value === FlowStatus.Failed)
        return <span className={`flow-status tag red`}>{row.value}</span>;
      if (row.value === FlowStatus.Pending)
        return <span className={`flow-status tag grey`}>{row.value}</span>;
      if (row.value === FlowStatus.Unkown)
        return <span className={`flow-status tag red`}>{row.value}</span>;
      return row.value;
    }
  },
  {
    Header: <span className="date">Last Edited</span>,
    accessor: 'updated',
    className: 'flow-created number',
    width: 180,
    Cell: row => {
      return (
        <span className="date" name="pen">
          {row.value}
        </span>
      );
    }
  }
];

FlowToolbar.propTypes = {
  flow: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  status: PropTypes.string
};

export default FlowToolbar;
