import { put, call } from 'redux-saga/effects';
import * as API from '../api';
import * as actionTypes from '../actions/actionTypes';
import { deactivateLoader } from './loaders';

import {
  showErrorNotification,
  showSuccessNotification
} from './notifications';

function* fetchDataTypes() {
  try {
    const { data } = yield call(API.fetchDataTypes);

    yield put({
      type: actionTypes.FETCH_DATATYPES_SUCCEEDED,
      payload: { data }
    });
  } catch (e) {
    yield put({ type: actionTypes.FETCH_DATATYPES_FAILED, message: e.message });
  }
}

function* fetchUnassignedDataSources() {
  try {
    const { data: unassignedDataSources } = yield call(
      API.fetchUnassignedDataSources
    );

    yield put({
      type: actionTypes.FETCH_UNASSIGNED_DATASOURCES_SUCCEEDED,
      payload: { data: unassignedDataSources }
    });
  } catch (e) {
    yield put({
      type: actionTypes.FETCH_UNASSIGNED_DATASOURCES_FAILED,
      message: e.message
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'dataNodeDetails.table' }
    });
  }
}

function* fetchDataSources() {
  try {
    const { data } = yield call(API.fetchDataSources);

    yield put({
      type: actionTypes.FETCH_DATASOURCES_SUCCEEDED,
      payload: { data }
    });

    yield* fetchDataNodes();
  } catch (e) {
    yield put({
      type: actionTypes.FETCH_DATASOURCES_FAILED,
      message: e.message
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'dataNodeDetails.table' }
    });
  }
}

function* fetchDataSourcesByDataNodeId(action) {
  const { dataNodeId } = action.payload;
  try {
    const { data } = yield call(API.fetchDataSourcesByDataNodeId, {
      dataNodeId
    });

    yield put({
      type: actionTypes.FETCH_DATASOURCES_BY_DATANODE_ID_SUCCEEDED,
      payload: { data }
    });
  } catch (e) {
    yield put({
      type: actionTypes.FETCH_DATASOURCES_BY_DATANODE_ID_FAILED,
      message: e.message
    });
  } finally {
    yield deactivateLoader({
      payload: { path: 'dataNodeDetails.table' }
    });
  }
}

function* fetchDataNodes() {
  try {
    const { data } = yield call(API.fetchDataNodes);

    yield put({
      type: actionTypes.FETCH_DATANODES_SUCCEEDED,
      payload: { data }
    });
  } catch (e) {
    yield put({ type: actionTypes.FETCH_DATANODES_FAILED, message: e.message });
  } finally {
    yield deactivateLoader({
      payload: { path: 'dataNodes.table' }
    });
  }
}

function* updateDataSources(action) {
  const sources = action.payload;

  try {
    const payload = sources.map(dataSource => {
      const { ID: dataSourceID, ...dataSourceData } = dataSource;
      return {
        ...dataSourceData,
        dataSourceID
      };
    });

    yield call(API.updateDataSources, payload);

    yield put({
      type: actionTypes.UPDATE_DATASOURCES_SUCCEEDED
    });
    yield* showSuccessNotification({
      content: 'Data sources updated.'
    });
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_DATASOURCES_FAILED,
      message: e.message
    });
    yield* showErrorNotification({
      content: 'Error updating data sources.'
    });
  } finally {
    yield* fetchDataSources();
  }
}

function* updateDataSource(source) {
  yield call(API.updateDataSource, source.ID, source);
}

function* deleteDataSource(action) {
  const { dataSourceId, dataNodeId } = action.payload;
  try {
    const { data } = yield call(API.deleteDataSource, { dataSourceId });

    yield put({
      type: actionTypes.DELETE_DATASOURCE_SUCCEEDED,
      payload: { data }
    });

    yield* showSuccessNotification({
      content: 'Data source deleted.'
    });
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_DATASOURCE_FAILED,
      message: e.message
    });
  } finally {
    if (dataNodeId) {
      yield* fetchDataSourcesByDataNodeId({ payload: { dataNodeId } });
    }
    yield* fetchDataSources();
  }
}

function* deleteDataNode(action) {
  const { dataNodeId } = action.payload;
  try {
    const { data } = yield call(API.deleteDataNode, { dataNodeId });

    yield put({
      type: actionTypes.DELETE_DATANODE_SUCCEEDED,
      payload: { data }
    });

    yield* showSuccessNotification({
      content: 'Data node deleted.'
    });
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_DATANODE_FAILED,
      message: e.message
    });
  } finally {
    yield* fetchDataNodes();
  }
}

function* createDataNode(action) {
  const dataNode = action.payload.dataNode;
  const dataSources = action.payload.dataSources;

  try {
    const {
      data: { response: createdDataNode }
    } = yield call(API.createDataNode, dataNode);

    yield put({
      type: actionTypes.CREATE_DATANODE_SUCCEEDED,
      payload: null
    });

    const dataNodeId = createdDataNode.ID;
    const mappedDataSources = dataSources.map(source => {
      return { ...source, dataNodeID: dataNodeId };
    });

    yield put({
      type: actionTypes.UPDATE_DATASOURCES_REQUESTED,
      payload: mappedDataSources
    });
  } catch (e) {
    yield put({
      type: actionTypes.CREATE_DATANODE_FAILED,
      message: e.message
    });
  }
}

function* uploadDataSourcesCsv(action) {
  const file = action.payload;

  var bodyFormData = new FormData();

  bodyFormData.append('csvupload', file);

  try {
    const {
      data: { response }
    } = yield call(API.uploadCsvFile, bodyFormData);

    yield put({
      type: actionTypes.UPLOAD_DATASOURCES_CSV_SUCCEEDED
    });
    yield* showSuccessNotification({
      content: 'Data sources successfully imported.'
    });

    if (Object.keys(response.errors).length > 0) {
      yield* showErrorNotification({
        content: JSON.stringify(response.errors)
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.UPLOAD_DATASOURCES_CSV_FAILED,
      message: e.message
    });
    yield* showErrorNotification({
      content: 'Error importing data sources.'
    });
  } finally {
    yield* fetchUnassignedDataSources();
  }
}

export {
  fetchDataNodes,
  fetchDataTypes,
  fetchDataSources,
  fetchUnassignedDataSources,
  fetchDataSourcesByDataNodeId,
  updateDataSource,
  deleteDataSource,
  createDataNode,
  updateDataSources,
  uploadDataSourcesCsv,
  deleteDataNode
};
