import { takeEvery, takeLatest, debounce } from 'redux-saga/effects';

import * as actionTypes from 'store/actions/actionTypes';

import {
  inviteUsers,
  authorizeNewUser,
  resetPassword,
  setPassword,
  loginUser,
  logoutUser,
  registerUser,
  checkAuth
} from './auth';
import {
  fetchDataNodes,
  fetchDataTypes,
  fetchDataSources,
  fetchUnassignedDataSources,
  fetchDataSourcesByDataNodeId,
  updateDataSources,
  deleteDataSource,
  createDataNode,
  uploadDataSourcesCsv,
  deleteDataNode
} from './sources';
import { sendLocationAnalyticsEvent, closeRightPanel } from './router';
import { setLoader } from './loaders';
import { updateCustomer, getCustomerById } from './customers';
import { getUsers, deleteUser, updateUser } from './users';
import {
  createFlow,
  updateFlow,
  updateFlowNodes,
  fetchNodeDefinitions,
  deleteFlow,
  destroyFlow,
  deployFlow,
  cloneFlow,
  redeployNode
} from './flow';
import {
  createIntegrationCredentials,
  deleteIntegrationCredentials,
  updateIntegrationCredentials
} from './integrations';

import {
  loadPersistentState,
  clearPersistentStateItem,
  addPersistentStateItem,
  removePersistentStateItems
} from './persistentState';
import { getFlowKpis } from './kpi';
import { batchEvaluateFlags, evaluateFlag } from './featureFlags';

function* rootSaga() {
  /*
    Persistent state
  */

  yield takeEvery(
    actionTypes.LOAD_PERSISTENT_STATE_REQUESTED,
    loadPersistentState
  );
  yield takeEvery(
    actionTypes.CLEAR_PERSISTENT_STATE_ITEM_REQUESTED,
    clearPersistentStateItem
  );
  yield takeEvery(
    actionTypes.ADD_PERSISTENT_STATE_ITEM_REQUESTED,
    addPersistentStateItem
  );
  yield takeEvery(
    actionTypes.REMOVE_PERSISTENT_STATE_ITEMS_REQUESTED,
    removePersistentStateItems
  );

  /*
    Routing
  */
  yield takeEvery(actionTypes.LOCATION_CHANGE, [
    sendLocationAnalyticsEvent, // sends gtag analytics events
    closeRightPanel // closes right panel if it's opened during routing change
  ]);
  /*
    USER's sagas
  */
  yield takeEvery(actionTypes.USERS_INVITE_REQUESTED, inviteUsers);
  yield takeEvery(actionTypes.GET_USERS_REQUESTED, getUsers);
  yield takeEvery(actionTypes.DELETE_USER_REQUESTED, deleteUser);
  yield takeEvery(actionTypes.UPDATE_USER_REQUESTED, updateUser);

  /*
    Applies changes to loaders state
  */
  yield takeEvery(actionTypes.SET_LOADER_REQUESTED, setLoader);

  /*
    Authorizes user with jwt token (used when users click on magic link from email)
  */
  yield takeLatest(actionTypes.AUTH_CHECK_REQUESTED, checkAuth);
  yield takeLatest(actionTypes.AUTHORIZE_USER_REQUESTED, authorizeNewUser);
  yield takeLatest(actionTypes.LOG_IN_USER_REQUESTED, loginUser);
  yield takeLatest(actionTypes.LOG_OUT_USER_REQUESTED, logoutUser);
  yield takeLatest(actionTypes.REGISTER_USER_REQUESTED, registerUser);
  /*
    Sends a link to user email to reset password
  */
  yield takeEvery(actionTypes.RESET_PASSWORD_REQUESTED, resetPassword);

  /*
    User sets new password when registers/ when re-seted password through the email
  */
  yield takeEvery(actionTypes.AUTH_SET_PASSWORD_REQUESTED, setPassword);

  /*
    Handles actions related to customer (organization) details
  */
  yield takeEvery(
    actionTypes.UPDATE_CUSTOMER_DETAILS_REQUESTED,
    updateCustomer
  );

  yield takeEvery(actionTypes.GET_CUSTOMER_DETAILS_REQUESTED, getCustomerById);

  /* DATASOURCES */

  yield takeEvery(actionTypes.UPDATE_DATASOURCES_REQUESTED, updateDataSources);

  yield takeEvery(actionTypes.DELETE_DATASOURCE_REQUESTED, deleteDataSource);

  yield takeEvery(
    actionTypes.FETCH_DATASOURCES_BY_DATANODE_ID_REQUESTED,
    fetchDataSourcesByDataNodeId
  );

  yield takeEvery(
    actionTypes.UPLOAD_DATASOURCES_CSV_REQUESTED,
    uploadDataSourcesCsv
  );

  /*
    Fetch unassigned data sources
   */
  yield takeEvery(
    actionTypes.FETCH_UNASSIGNED_DATASOURCES_REQUESTED,
    fetchUnassignedDataSources
  );

  yield takeEvery(actionTypes.FETCH_DATASOURCES_REQUESTED, fetchDataSources);
  /*
    Fetch data nodes (data sources)
  */
  yield takeEvery(actionTypes.FETCH_DATANODES_REQUESTED, fetchDataNodes);

  /*
    Delete data node
   */
  yield takeEvery(actionTypes.DELETE_DATANODE_REQUESTED, deleteDataNode);

  /*
    Fetch data types
   */
  yield takeEvery(actionTypes.FETCH_DATATYPES_REQUESTED, fetchDataTypes);

  /*
    Create new data node
   */

  yield takeEvery(actionTypes.CREATE_DATANODE_REQUESTED, createDataNode);
  /*
    Update configration for a particular node
    takeLatest means that if saga is in progress and other action comes
    previous is being canceled and new one is being executed
   */
  yield debounce(
    1000,
    actionTypes.UPDATE_FLOW_NODES_REQUESTED,
    updateFlowNodes
  );
  /*
    Create flow (name + description)
   */
  yield takeEvery(actionTypes.CREATE_FLOW_REQUESTED, createFlow);
  /*
    Update flow (name + description)
   */
  yield takeEvery(actionTypes.UPDATE_FLOW_REQUESTED, updateFlow);
  /*
    Delete flow by Id
   */
  yield takeEvery(actionTypes.DELETE_FLOW_REQUESTED, deleteFlow);
  /*
    Destroy flow by Id
   */
  yield takeEvery(actionTypes.DESTROY_FLOW_REQUESTED, destroyFlow);
  /*
    Deploy flow by Id
   */
  yield takeEvery(actionTypes.DEPLOY_FLOW_REQUESTED, deployFlow);
  /*
    Re-deploy flow's node by Id
   */
  yield takeEvery(actionTypes.REDEPLOY_FLOW_NODE_REQUESTED, redeployNode);
  /*
    Clone flow
   */
  yield takeEvery(actionTypes.CLONE_FLOW_REQUESTED, cloneFlow);
  /*
    Fetch node definitions (default nodes + data nodes)
  */
  yield takeEvery(
    actionTypes.FETCH_NODE_DEFINITIONS_REQUESTED,
    fetchNodeDefinitions
  );

  /*
    Flows kpi (metrics)
   */
  yield takeEvery(actionTypes.GET_FLOW_KPI_REQUESTED, getFlowKpis);

  /*
    INTEGRATIONS
  */
  yield takeEvery(
    actionTypes.CREATE_INTEGRATION_CREDENTIALS_REQUESTED,
    createIntegrationCredentials
  );
  yield takeEvery(
    actionTypes.DELETE_INTEGRATION_CREDENTIALS_REQUESTED,
    deleteIntegrationCredentials
  );
  yield takeEvery(
    actionTypes.UPDATE_INTEGRATION_CREDENTIALS_REQUESTED,
    updateIntegrationCredentials
  );

  /*
    Batch evaluate feature flags (also re-evaluate once AUTH_CHECK is done with user data)
   */
  yield takeEvery(
    [
      actionTypes.AUTH_CHECK_REQUESTED,
      actionTypes.BATCH_EVALUATE_FEATURE_FLAGS_REQUESTED
    ],
    batchEvaluateFlags
  );

  /*
    Evaluate single feature flag
   */
  yield takeEvery(actionTypes.EVALUATE_FEATURE_FLAG_REQUESTED, evaluateFlag);
}

export default rootSaga;
