import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useDropdown from 'hooks/useDropdown';
import { Status, Icon, Button, Search } from 'components';
import ClickOutside from 'hoc/ClickOutside/ClickOutside';
import './Dropdown.scss';

export const BaseDropdown = ({
  values = [],
  onItemSelected = () => {},
  placeholder,
  leftIcon,
  rightIcon,
  isSearchable,
  disabled = false,
  open,
  setOpen,
  selected,
  showStatus = false,
  fullWidth
}) => {
  const [toggle, setToggle] = useState('');
  const [search, setSearch] = useState('');
  const [selectedItem, setSelectedItem] = useState(selected);

  const toggleDropdown = useCallback(() => {
    setOpen(!open);
    setToggle(!open ? 'toggle' : '');
  }, [open, setOpen]);

  const handleSelect = value => e => {
    e.preventDefault();
    setSelectedItem(value);
    onItemSelected && onItemSelected(value);
    toggleDropdown();
  };

  const onSearchHandler = useCallback(event => {
    setSearch(event.target.value);
  }, []);

  const handleSearchClear = useCallback(() => {
    setSearch('');
  }, []);

  const rowFilter = row => {
    const filter = search;

    if (filter) {
      for (let key in row) {
        if (!row[key]) continue;

        if (
          row[key]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase())
        ) {
          return true;
        }
      }

      return false;
    } else return true;
  };

  const headerTitle = selectedItem ? selectedItem.value : placeholder;

  function handleClickOutside() {
    setOpen(false);
    setToggle('');
  }

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <div
        className={classnames('dropdown-container', {
          'dropdown-full-width': fullWidth
        })}
      >
        <div
          onClick={toggleDropdown}
          className={classnames('dropdown-header', toggle, {
            'dropdown__not-selected': !selected
          })}
        >
          {leftIcon ? (
            <Icon className="dropdown-header-info" name={['fas', leftIcon]} />
          ) : null}

          {showStatus && selected ? <Status status={selected.status} /> : ''}
          <Button
            className="dropdown-header-button"
            type="button"
            aria-haspopup="true"
            aria-expanded={open}
            id="dropdownMenuButton"
            label={headerTitle}
            title={headerTitle}
            disabled={disabled}
          />
          {rightIcon ? (
            <Icon className="dropdown-header-caret" name={['fas', rightIcon]} />
          ) : null}
        </div>
        <div className="dropdown-list-wrapper">
          {open && !disabled && (
            <ul className="dropdown-list">
              {isSearchable ? (
                <li key="search" className="dropdown-list-search">
                  <Search
                    type="search"
                    placeholder="Filter by name"
                    onChange={onSearchHandler}
                    value={search}
                    onClearClick={handleSearchClear}
                  />
                </li>
              ) : null}
              {values.filter(rowFilter).map(item => (
                <li
                  className="dropdown-list-item"
                  key={item.key}
                  onClick={handleSelect(item)}
                  title={item.value}
                >
                  {item.status && <Status status={item.status} />}
                  {item.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </ClickOutside>
  );
};

const defaultEntity = {
  singular: 'item',
  plural: 'items'
};

export const MultiSelectionDropdown = ({
  values = [],
  shouldShow,
  selectedItems,
  onSelect,
  isSelected,
  setShouldShow,
  disabled = false,
  entity = defaultEntity
}) => {
  const numberOfItemsSelected = selectedItems.length;

  const toggleDropdown = useCallback(() => {
    setShouldShow(!shouldShow);
  }, [shouldShow, setShouldShow]);

  const handleClickOutside = useCallback(() => {
    setShouldShow(false);
  }, [setShouldShow]);

  const handleSelect = useCallback(
    item => e => {
      e.preventDefault();
      onSelect(item);
    },
    [onSelect]
  );

  const headerTitle =
    numberOfItemsSelected === 1
      ? `${numberOfItemsSelected} ${entity.singular} selected`
      : `${numberOfItemsSelected} ${entity.plural} selected`;

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <div className="dropdown-container">
        <div
          onClick={toggleDropdown}
          className={classnames('dropdown-header', {
            toggle: shouldShow
          })}
        >
          <Button
            className={'dropdown-header-button'}
            type="button"
            aria-haspopup="true"
            aria-expanded={shouldShow}
            id="dropdownMenuButton"
            label={headerTitle}
            title={headerTitle}
            disabled={disabled}
          />
          <Icon
            className="dropdown-header-caret"
            name={['fas', 'caret-down']}
          />
        </div>
        <div className="dropdown-list-wrapper">
          {shouldShow && (
            <ul className="dropdown-list">
              {values.map(item => (
                <li
                  className={`dropdown-list-item ${
                    isSelected(item) ? 'dropdown-list-item-selected' : ''
                  }`}
                  key={item.key}
                  onClick={handleSelect(item)}
                  title={item.value}
                >
                  {isSelected(item) && <Icon name={['far', 'check']} />}
                  {item.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </ClickOutside>
  );
};

const Dropdown = props => {
  const { shouldShow, setShouldShow } = useDropdown();
  return <BaseDropdown {...props} open={shouldShow} setOpen={setShouldShow} />;
};

BaseDropdown.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any, // eslint-disable-line
      text: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  onItemSelected: PropTypes.func,
  variant: PropTypes.string,
  isSearchable: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  fullWidth: PropTypes.bool
};

Dropdown.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any, // eslint-disable-line
      text: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  onItemSelected: PropTypes.func,
  variant: PropTypes.string,
  isSearchable: PropTypes.bool
};

export default Dropdown;
