import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Icon } from 'components';

import classNames from 'classnames';

class Links extends Component {
  handleClick = disabled => event => {
    if (disabled) event.preventDefault();
  };

  render() {
    return (
      <React.Fragment>
        <ReactTooltip place="bottom" type="dark" effect="solid" />
        {this.props.links.map(link => {
          const className = classNames({
            button: link.className === 'button',
            'disabled-link': link.disabled
          });

          switch (link.type) {
            case 'method':
              return (
                <div
                  key={link.key}
                  id={link.id}
                  onClick={link.onClick}
                  className={className + ' action-link'}
                >
                  {link.icon ? (
                    <Icon name={link.icon} className="icon" />
                  ) : null}
                  <span className="label">{link.text}</span>
                </div>
              );
            default:
              return (
                <NavLink
                  key={link.key}
                  id={link.id}
                  className={className}
                  to={link.href}
                  onClick={this.handleClick(link.disabled)}
                  data-tip={link.tooltip}
                >
                  {link.icon ? (
                    <Icon name={link.icon} className="icon" />
                  ) : null}
                  <span>{link.text}</span>
                </NavLink>
              );
          }
        })}
      </React.Fragment>
    );
  }
}

Links.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      href: PropTypes.string,
      disabled: PropTypes.bool,
      tooltip: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    }).isRequired
  )
};

export default Links;
