import { format } from 'date-fns';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import {
  findArrayObjectById,
  filterArrayByParameter,
  isArray,
  isEmpty,
  findArrayValueById
} from './transform';
import { MOCK_NODE_TYPES } from 'mocks/mockTemplate';
import { NodeParameters } from 'constants';

export const transformNodeTypes = nodeTypes => {
  if (!Array.isArray(nodeTypes) || !nodeTypes.length) return [];

  return MOCK_NODE_TYPES;
};

export const extractNodeGroups = nodeTypes => {
  if (!Array.isArray(nodeTypes) || !nodeTypes.length) return [];

  // Create a set to remove duplicate entries with All as default
  const groups = new Set(['All']);

  nodeTypes.forEach(node => {
    if (node.group) {
      groups.add(node.group);
    }
  });

  return Array.from(groups).map((group, index) => {
    return {
      value: group,
      index: index
    };
  });
};

export const nodeTypesSortStrategy = (a, b) => {
  try {
    const PRIORITY = {
      all: 1,
      input: 2,
      process: 3,
      output: 4,
      storage: 5
    };

    const firstVal = a.toLowerCase();
    const secondVal = b.toLowerCase();
    if (PRIORITY[firstVal] && PRIORITY[secondVal]) {
      return PRIORITY[firstVal] < PRIORITY[secondVal] ? -1 : 1;
    } else {
      return 0;
    }
  } catch {
    return 0;
  }
};

export const getNodesForGroup = (nodes, group) => {
  return nodes.filter(node => node.group === group || group === 'All');
};

export const getNodeById = (nodes, id) => {
  return nodes.find(node => node.ID === id);
};

export const getNodeTypeById = (nodesTypes, id) => {
  return nodesTypes.find(type => type.ID === id);
};

export const getNodeByExternalId = (nodes, id) => {
  return nodes.find(node => node.externalId === id);
};

export const exportFlowNodes = (flowId, nodes) => {
  if (!nodes) return [];

  const filteredNodes = nodes.filter(
    node => !node.type.toLowerCase().includes('link')
  );
  if (!filteredNodes) return [];

  return filteredNodes.map(node => {
    return {
      id: node.externalId || node.id,
      flowId: flowId,
      nodeTypeId: node.nodeTypeId,
      x: node.position ? Math.floor(node.position.x) : null,
      y: node.position ? Math.floor(node.position.y) : null
    };
  });
};

export const getSourceNodeConnectors = (nodeTypes, id) => {
  const node = getNodeById(nodeTypes, id);
  return node ? node.connectors : [];
};

export const getDestinationNodeConnectors = (inPorts, nodeTypes) => {
  if (!inPorts || inPorts.length === 0 || !nodeTypes || nodeTypes.length === 0)
    return [];

  const destinationConnectors = [];
  inPorts.forEach(port => {
    nodeTypes.forEach(({ connectors }) => {
      const filteredArray = filterArrayByParameter(
        connectors,
        port.ID,
        'destinationPortID'
      );

      if (filteredArray.length > 0) {
        destinationConnectors.push(...filteredArray);
      }
    });
  });
  return uniqWith(destinationConnectors, isEqual) || [];
};

export const validateConnection = (
  nodeTypes,
  source,
  destination,
  sourceMagnet,
  destinationMagnet,
  end,
  nodes
) => {
  if (
    nodeTypes.length <= 0 ||
    !source ||
    !destination ||
    source === destination
  )
    return false;

  if (!destinationMagnet) return false;

  // Prevent linking to output ports.
  if (
    destinationMagnet &&
    (!destinationMagnet.getAttribute('type') ||
      destinationMagnet.getAttribute('type') === 'output')
  )
    return false;

  // Check if destination ports match
  let sourcePort = sourceMagnet ? sourceMagnet.getAttribute('port') : '';
  let destinationPort = destinationMagnet
    ? destinationMagnet.getAttribute('port')
    : '';

  if (sourcePort && destinationPort) {
    sourcePort = sourcePort.split('_');
    destinationPort = destinationPort.split('_');
    const sourcePortID =
      isArray(sourcePort) && sourcePort.length === 2 ? sourcePort[1] : null;
    const destinationPortID =
      isArray(destinationPort) && destinationPort.length === 2
        ? destinationPort[1]
        : null;
    const sourceNodeId =
      isArray(sourcePort) && sourcePort.length === 2 ? sourcePort[0] : null;

    const sourceNode = nodes.filter(
      item => item['externalId'] === sourceNodeId || item['id'] === sourceNodeId
    );
    const sourceNodePorts =
      sourceNode && sourceNode[0].ports && sourceNode[0].ports.items;

    for (let port of sourceNodePorts) {
      for (let connector of port.connectors) {
        if (
          connector.sourcePortID === sourcePortID &&
          connector.destinationPortID === destinationPortID
        ) {
          return true;
        }
      }
    }

    return false;
  } else return false;
};

export const exportFlowNodeConnectors = (flowId, nodes) => {
  if (!nodes) return [];

  const linkNodes = nodes.filter(node =>
    node.type.toLowerCase().includes('link')
  );
  if (!linkNodes) return [];

  return linkNodes.map(node => {
    const sourceNode = nodes.filter(
      filterNode => filterNode.id === node.source.id
    );
    const destinationNode = nodes.filter(
      filterNode => filterNode.id === node.target.id
    );

    return {
      id: node.id,
      flowId: flowId,
      sourceNodeId:
        sourceNode && sourceNode[0] ? sourceNode[0].externalId : node.source.id,
      destinationNodeId:
        destinationNode && destinationNode[0]
          ? destinationNode[0].externalId
          : node.target.id
    };
  });
};

export const templatesTableData = templates => {
  if (!templates || templates.length === 0) return [];

  return templates.map(({ name, created }) => {
    return {
      name,
      created: format(created, 'DD.MM.YYYY')
    };
  });
};

export const flowsTableData = flows => {
  if (!flows || flows.length <= 0) return [];

  return flows.map(flow => ({
    ID: flow.ID,
    name: flow.name,
    description: flow.description,
    updated: format(flow.updated, 'DD.MM.YYYY'),
    status: flow.status,
    example: flow.example
  }));
};

export const getNodeTypeName = (nodeTypes, id) => {
  if (!nodeTypes) return id;

  const nodeType = getNodeTypeById(nodeTypes, id);

  return nodeType ? nodeType.name : id;
};

export const getNodeTypeParameters = (nodeTypes, nodeTypeID) => {
  const nodeType = findArrayObjectById(nodeTypes, nodeTypeID);

  if (!nodeType) return [];

  return nodeType.parameters || [];
};

export const getFieldName = (type, id, flowNodeID, nodeTypeParameterID) => {
  if (!type || !id || !flowNodeID || !nodeTypeParameterID) return '';

  return `${type}_${id}_${flowNodeID}_${nodeTypeParameterID}`;
};

export const parseField = field => {
  const [type, ID, flowNodeID, nodeTypeParameterID] = field.split('_');
  if (!type || !ID || !flowNodeID || !nodeTypeParameterID) return {};

  return {
    type,
    ID,
    flowNodeID,
    nodeTypeParameterID
  };
};

export const configurationsTableData = (configurations, flows) => {
  if (!configurations || configurations.length === 0) return [];
  return configurations.map(({ ID, name, status, flowID, created }) => {
    return {
      ID,
      name,
      status,
      flow: findArrayValueById(flows, flowID, 'name'),
      created: format(created, 'DD.MM.YYYY')
    };
  });
};

export const getConfigurationParameters = (nodesWithParameters, parameters) => {
  if (!nodesWithParameters || !parameters) return [];

  const configurationParameters = [];

  for (let key in parameters) {
    if (!parameters[key]) continue;

    const parameter = parseField(key);

    if (isEmpty(parameter)) continue;

    const nodeTypeParameters = findArrayValueById(
      nodesWithParameters,
      parameter.flowNodeID,
      'parameters'
    );

    if (isEmpty(nodeTypeParameters)) {
      continue;
    }

    const nodeTypeParameter = findArrayObjectById(
      nodeTypeParameters,
      parameter.nodeTypeParameterID,
      'nodeTypeParameterID'
    );

    if (isEmpty(nodeTypeParameter)) {
      continue;
    }
    configurationParameters.push({
      ID: parameter.ID,
      nodeTypeParameterID: parameter.nodeTypeParameterID,
      nodeTypeID: nodeTypeParameter.nodeTypeID,
      flowNodeID: parameter.flowNodeID,
      name: nodeTypeParameter.name,
      type: nodeTypeParameter.type,
      value: parameters[key]
    });
  }

  return configurationParameters;
};

export const getConfigurationData = (flowID, name, parameters) => {
  return {
    name,
    flowID,
    parameters: parameters || {}
  };
};

export const getConfigurationEventKey = (
  nodeTypeID,
  nodeParameters,
  parameters,
  flowNodeID
) => {
  if (!nodeParameters || !parameters) return null;

  for (let parameter of parameters) {
    if (
      parameter.nodeTypeID === nodeTypeID && flowNodeID
        ? flowNodeID === parameter.flowNodeID
        : true
    ) {
      const eventObject = findArrayObjectById(
        nodeParameters,
        parameter.nodeTypeParameterID
      );

      if (eventObject && eventObject.variable === NodeParameters.EVENT_KEY) {
        return parameter.value;
      }
    }
  }

  return null;
};

export function filterNodeParameters(parameters, flowID) {
  return parameters.map(
    ({ ID, flowNodeID, nodeTypeID, nodeTypeParameterID, value }) => ({
      ID,
      flowID,
      flowNodeID,
      nodeTypeID,
      nodeTypeParameterID,
      value
    })
  );
}

export const FLOW_STATUS = {
  stopped: 'stopped',
  pending: 'pending',
  running: 'running',
  terminating: 'terminating',
  failed: 'failed',
  unknown: 'unknown'
};
