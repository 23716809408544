import React, { useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { Field, Button, Dropdown, ToggleSwitch } from 'components';

import './AssignDataSourcesForm.scss';

const AssignDataSourcesForm = ({
  data,
  dataType,
  handleCancel,
  confirmModal,
  selection
}) => {
  const dropdownNodes = data
    .filter(node => node.dataTypeName === dataType)
    .map(node => {
      return {
        value: node.name,
        id: node.ID,
        key: node.ID
      };
    });

  const isDropdownNodesEmpty = dropdownNodes.length === 0;

  const [dataNodeId, setDataNodeId] = useState('');
  const [isToggleChecked, setToggleChecked] = useState(false);

  const dataTypeId = selection[0]?.dataTypeID;

  const onNodeSelected = useCallback(
    value => {
      setDataNodeId(value.id);
    },
    [setDataNodeId]
  );

  const selectionWithDataNodeId = selection.map(item => ({
    ...item,
    dataNodeID: dataNodeId
  }));

  const handleToggle = useCallback(event => {
    setToggleChecked(event.target.checked);

    // necessary for submit to become unavailable when toggling back and forth
    // after picking a dataNodeId (essentially we're clearing local state since dropdown got unmounted)
    if (!event.target.checked) setDataNodeId('');
  }, []);

  const handleSubmit = useCallback(
    value => {
      if (isToggleChecked || isDropdownNodesEmpty) {
        if (value && value.text) {
          const dataNodeObject = {
            name: value.text,
            dataTypeID: dataTypeId
          };

          confirmModal(selection, dataNodeObject);
        }
      } else {
        if (selectionWithDataNodeId) {
          confirmModal(selectionWithDataNodeId);
        }
      }
    },
    [
      selectionWithDataNodeId,
      isToggleChecked,
      isDropdownNodesEmpty,
      confirmModal,
      dataTypeId,
      selection
    ]
  );

  return (
    <div className="sources-wrapper">
      <div className="sources-form">
        <Formik enableReinitialize initialValues={{}} onSubmit={handleSubmit}>
          {({ values }) => {
            const isCreatingDatanode = isToggleChecked || isDropdownNodesEmpty;
            const shouldShowDisabled = isCreatingDatanode
              ? !dataTypeId || !values?.text
              : !dataNodeId;

            return (
              <Form>
                {!isDropdownNodesEmpty ? (
                  <ToggleSwitch
                    label="Create new data node"
                    name="isNewDataNode"
                    checked={isToggleChecked}
                    onClick={handleToggle}
                  />
                ) : null}
                {isToggleChecked || isDropdownNodesEmpty ? (
                  <Field
                    id="data-node-name"
                    name="text"
                    type="text"
                    label="Assign to new data node"
                    placeholder="Data node name"
                  />
                ) : (
                  <div className="field-container">
                    <label htmlFor="">Assign to existing data node</label>
                    <Dropdown
                      name="createDataNode"
                      values={dropdownNodes}
                      onItemSelected={onNodeSelected}
                      label="Pick a data node"
                      placeholder="Pick a data node"
                      rightIcon="caret-down"
                    />
                  </div>
                )}
                <div className="footer">
                  <Button
                    id="cancel-btn"
                    type="button"
                    onClick={handleCancel}
                    label="Cancel"
                  />
                  <Button
                    id="save-btn"
                    type="submit"
                    className="primary"
                    label="Assign"
                    disabled={shouldShowDisabled}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

AssignDataSourcesForm.propTypes = {
  id: PropTypes.string,
  isUpdating: PropTypes.bool,
  isEditing: PropTypes.bool,
  isToggleDisabled: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired
};

export default AssignDataSourcesForm;
