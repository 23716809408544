import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import upperCase from 'lodash/upperCase';
import capitalize from 'lodash/capitalize';

import { Dropdown } from 'components';
import useDropdown from 'hooks/useDropdown';

import { parseField } from 'utilities';
import { integrationByTypeSelector } from 'store/selectors';

const Integrations = ({ handleSelect, integrationType }) => {
  const [selectedItemKey, setSelectedItemKey] = useState(null);

  const {
    shouldShow: shouldShowDropdown,
    setShouldShow: setShouldShowDropdown
  } = useDropdown();

  const integrations = useSelector(
    integrationByTypeSelector(upperCase(integrationType))
  );

  const integrationsExist = useMemo(() => integrations.length > 0, [
    integrations
  ]);

  const selectedItem = useMemo(
    () => integrations.find(i => i.ID === selectedItemKey),
    [integrations, selectedItemKey]
  );

  const dropdownValues = useMemo(
    () => integrations.map(({ title, ID }) => ({ value: title, key: ID })),
    [integrations]
  );

  const handleDropdownSelect = useCallback(({ key }) => {
    setSelectedItemKey(key);
  }, []);

  useEffect(() => {
    if (integrationType && selectedItem) {
      handleSelect(values => {
        const output = {};
        Object.entries(values).forEach(([key, value]) => {
          const { nodeTypeParameterID: ID } = parseField(key);
          const matchingField = selectedItem.values.find(
            field => field.ID === ID
          );
          output[key] = matchingField ? matchingField.value : value;
        });
        return output;
      });
    }
  }, [selectedItem, integrationType]); // eslint-disable-line

  if (!integrationType) {
    return null;
  }

  return (
    <div className="field-container">
      <label>INTEGRATION</label>
      {integrationsExist ? (
        <Dropdown
          values={dropdownValues}
          placeholder="Select Integration"
          onItemSelected={handleDropdownSelect}
          open={shouldShowDropdown}
          setOpen={setShouldShowDropdown}
          selected={selectedItem}
          rightIcon="caret-down"
          fullWidth
        />
      ) : (
        <p>
          <NavLink to="/integrations">{`${capitalize(
            integrationType
          )} integration `}</NavLink>
          should be created first
        </p>
      )}
    </div>
  );
};

export default Integrations;
