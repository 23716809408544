import { toast } from 'react-toastify';
import { put } from 'redux-saga/effects';

import {
  PERSISTENT_NOTIFICATIONS,
  addPersistentStateItem
} from 'store/actions/persistentState';

function* showNotification({
  type,
  content,
  position = 'top-right',
  autoClose = 5000
}) {
  yield toast(content, {
    type,
    position,
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    closeButton: false,
    className:
      'Toastify__slide-enter--top-right Toastify__slide-exit--top-right',
    draggable: false
  });
}

function* showSuccessNotification({ ...config }) {
  const notificationProps = { ...config, type: 'success' };

  yield* showNotification(notificationProps);

  yield put(
    addPersistentStateItem(PERSISTENT_NOTIFICATIONS, {
      ...notificationProps,
      created: Date.now()
    })
  );
}

function* showErrorNotification({ ...config }) {
  const notificationProps = { ...config, type: 'error' };

  yield* showNotification(notificationProps);

  yield put(
    addPersistentStateItem(PERSISTENT_NOTIFICATIONS, {
      ...notificationProps,
      created: Date.now()
    })
  );
}

export { showSuccessNotification, showErrorNotification };
