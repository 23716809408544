import produce from 'immer';
import update from 'lodash/update';
import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  auth: {
    page: false
  },
  accountDetails: {
    page: true,
    inviteUsers: false
  },
  dataNodes: {
    table: true
  },
  dataNodeDetails: {
    table: true
  },
  passwordReset: {
    isSent: false
  }
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADER:
      const { path, value } = action.payload;

      if (path) {
        update(draft, path, () => value);
      }

      break;

    default:
      break;
  }
}, initialState);

export default reducer;
