import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Accordion.scss';

const Accordion = ({ className, id, title, activeId, body, onToggle }) => {
  const content = useRef(null);
  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0px'); // eslint-disable-line

  const toggleAccordion = useCallback(() => {
    onToggle(id);
  }, [id, onToggle]);

  useEffect(() => {
    const isActive = activeId !== '' && activeId === id;

    setActive(isActive ? 'active' : '');
    setHeight(isActive ? `${content.current.scrollHeight}px` : '0px');
  }, [id, active, activeId]);

  return (
    <div
      className={classnames(className || 'accordion-section', active)}
      key={id}
    >
      <div
        className={classnames('accordion-header', active)}
        onClick={toggleAccordion}
      >
        {title}
      </div>
      <div ref={content} className="accordion-content">
        {body}
      </div>
    </div>
  );
};

// Set default props
Accordion.defaultProps = {
  onToggle: () => void 0
};

Accordion.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  activeId: PropTypes.string,
  body: PropTypes.node.isRequired,
  onToggle: PropTypes.func
};

export default Accordion;
