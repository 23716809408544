import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { useParams, useHistory } from 'react-router-dom';

import { Tabs, Icon } from 'components';

import {
  accountDetailsPageLoaderSelector,
  authenticatedSelector
} from 'store/selectors';

import {
  AUTHORIZE_USER_REQUESTED,
  SET_LOADER_REQUESTED
} from 'store/actions/actionTypes';

import { ROUTES, ROUTING_CONSTANTS } from 'constants/routes';

import styles from './AccountDetails.module.scss';

import Settings from './Settings';
import Users from './Users';
import InviteUsersForm from './InviteUsersForm';

const tabs = [ROUTING_CONSTANTS.SETTINGS, ROUTING_CONSTANTS.USERS];

const AccountDetails = () => {
  const dispatch = useDispatch();
  const { tab, jwt } = useParams();
  const history = useHistory();
  const authenticated = useSelector(authenticatedSelector);
  const isAccountDetailsPageLoading = useSelector(
    accountDetailsPageLoaderSelector
  );

  const isUsersView = useMemo(() => tab === 'users', [tab]);

  const tabIndex = tabs.findIndex(i => i === tab);
  const handleSelect = useCallback(
    index => {
      const tabKey = tabs[index];
      if (tabKey) history.push(`/account/${tabKey}`);
    },
    [history]
  );

  const handleRedirect = useCallback(
    () => history.push(ROUTES.ACCOUNT.DETAILS.SETTINGS),
    [history]
  );

  useMount(() => {
    // If it's a route where user logs in for the first time
    // he/she has to be authorized via jwt token
    if (jwt) {
      dispatch({
        type: AUTHORIZE_USER_REQUESTED,
        payload: { jwt, redirect: handleRedirect }
      });
    } else {
      // Remaining all cases where user has already logged in before
      dispatch({
        type: SET_LOADER_REQUESTED,
        payload: { path: 'accountDetails.page', value: false }
      });
    }

    if (tabIndex < 0) {
      history.push(`/account/${tabs[0]}`);
    }
  });

  return !isAccountDetailsPageLoading && authenticated ? (
    <div className={styles.accountContainer}>
      <div className={styles.leftContainer}>
        <h1>Account</h1>
        <p>
          This page is an overview of your account and its authenticated users.
          In this section you will be able to change your credentials, invite
          users or remove them from this account.
        </p>
        <Tabs
          selectedTab={tabIndex}
          onSelect={handleSelect}
          tabs={[{ title: 'Settings' }, { title: 'Users' }]}
          panels={[<Settings />, <Users />]}
        />
      </div>
      {isUsersView ? <InviteUsersForm /> : null}
    </div>
  ) : (
    <div className="loader-container">
      <Icon name={['far', 'spinner']} spin />
    </div>
  );
};

export default AccountDetails;
