import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { Formik, Form } from 'formik';

import {
  getUsers,
  getCustomerDetailsById,
  updateCustomerDetails
} from 'store/actions/users';

import {
  authUserSelector,
  isAdminSelector,
  accountDetailsCustomerLoaderSelector
} from 'store/selectors';

import { ACCOUNT_SETTINGS_SCHEMA } from 'schema/schema';

import { Button, Field } from 'components';

import styles from './Settings.module.scss';
import './Settings.scss';

const Settings = () => {
  const dispatch = useDispatch();

  const user = useSelector(authUserSelector, isEqual);
  const isUserAdmin = useSelector(isAdminSelector, isEqual);
  const customer = useSelector(state => state.users.customerDetails) || {};
  const isAccountDetailsCustomerPending = useSelector(
    accountDetailsCustomerLoaderSelector,
    isEqual
  );

  const isInputDisabled = useMemo(() => !isUserAdmin, [isUserAdmin]);

  useEffect(() => {
    if (user?.customerID) {
      dispatch(getUsers());
      dispatch(getCustomerDetailsById(user.customerID));
    }
  }, [user, dispatch]);

  const handleSubmit = useCallback(
    data => {
      const customerData = {
        name: data.companyName,
        city: data.city,
        address: data.companyAddress,
        postalCode: data.postalCode,
        country: data.country,
        vat: data.vatNumber,
        phone: data.phoneNumber
      };

      const id = user?.customerID;
      dispatch(updateCustomerDetails(id, customerData));
    },
    [dispatch, user]
  );

  return (
    <Formik
      initialValues={{
        companyName: customer?.name || '',
        city: customer?.city || '',
        companyAddress: customer?.address || '',
        postalCode: customer?.postalCode || '',
        country: customer?.country || '',
        vatNumber: customer?.vat || '',
        phoneNumber: customer?.phone || ''
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={ACCOUNT_SETTINGS_SCHEMA}
    >
      <Form>
        <div className={`${styles.container} settings-form-container`}>
          <div>
            <Field
              type="text"
              name="companyName"
              label="Company Name"
              placeholder="Company name"
              disabled={isInputDisabled}
            />
            <Field
              type="text"
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone number"
              disabled={isInputDisabled}
            />
            <Field
              type="text"
              name="vatNumber"
              label="VAT Number"
              placeholder="VAT number"
              disabled={isInputDisabled}
            />
          </div>
          <div>
            <div className={styles.textareaField}>
              <Field
                control="textarea"
                as="textarea"
                type="textarea"
                name="companyAddress"
                label="Company Address"
                placeholder="Company Address"
                disabled={isInputDisabled}
              />
            </div>
            <Field
              type="text"
              name="city"
              label="City"
              placeholder="City"
              disabled={isInputDisabled}
            />
            <Field
              type="text"
              name="postalCode"
              label="Postal Code"
              placeholder="Postal code"
              disabled={isInputDisabled}
            />
            <Field
              type="text"
              name="country"
              label="Country"
              placeholder="Country"
              disabled={isInputDisabled}
            />
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.controls}>
          <div>
            <Button
              label="Request account deletion"
              className="link danger"
              disabled
            />
          </div>
          <div>
            <Button
              label="Manage integrations"
              className="outlined"
              disabled
              type="submit"
            />
            <Button
              label="Save account details"
              id="submit-details"
              className="primary"
              isLoading={isAccountDetailsCustomerPending}
              disabled={isInputDisabled}
              type="submit"
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default Settings;
