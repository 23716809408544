import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { Button } from 'components';

import { inviteUsers } from 'store/actions/users';
import { inviteUsersLoaderSelector } from 'store/selectors';
import styles from './InviteUsersForm.module.scss';

const InviteUsersForm = () => {
  const dispatch = useDispatch();

  const [usersToInviteString, setUsersToInviteString] = useState('');
  const isInviteUsersLoading = useSelector(inviteUsersLoaderSelector, isEqual);

  const handleInviteUsersValueChange = useCallback(
    event => {
      const { value } = event.target;

      setUsersToInviteString(value);
    },
    [setUsersToInviteString]
  );

  const handleInviteUsers = useCallback(() => {
    dispatch(inviteUsers(usersToInviteString));
  }, [dispatch, usersToInviteString]);

  return (
    <div className={styles.rightContainer} id="user-invite-form">
      <div className="label">Invite users</div>
      <textarea
        value={usersToInviteString}
        onChange={handleInviteUsersValueChange}
        name="addExtraUsers"
        placeholder="Add users to this account (email ids separated by newline or comma)"
      />
      <div className={styles.buttonContainer}>
        <Button
          id="invite-users-btn"
          onClick={handleInviteUsers}
          isLoading={isInviteUsersLoading}
          disabled={isInviteUsersLoading}
          label="Send invites"
          className="primary"
        />
      </div>
      <p>
        Learn more about user management and how RAIN manages your personal
        data. RAIN is GDPR compliant.
      </p>
    </div>
  );
};

export default InviteUsersForm;
