import { createSelector } from 'reselect';

import { FEATURE_FLAGS } from 'constants/featureFlags';

export const moduleState = createSelector(
  store => store.featureFlags,
  featureFlags => featureFlags
);

export const flagDataSelector = createSelector(
  moduleState,
  featureFlags => featureFlags?.data ?? null
);

export const registrationFeatureFlagSelector = createSelector(
  flagDataSelector,
  flagData => (flagData ? flagData.get(FEATURE_FLAGS.REGISTRATION) : null)
);

export const gtagFeatureFlagSelector = createSelector(
  flagDataSelector,
  flagData => (flagData ? flagData.get(FEATURE_FLAGS.GTAG) : null)
);
