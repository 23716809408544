import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, NoData, Button } from 'components';
import {
  clearPersistentState,
  PERSISTENT_NOTIFICATIONS
} from 'store/actions/persistentState';

import { persistentNotificationsSelector } from 'store/selectors';

const NotificationContainer = () => {
  const dispatch = useDispatch();

  const handleClearNotifications = useCallback(() => {
    dispatch(clearPersistentState(PERSISTENT_NOTIFICATIONS));
  }, [dispatch]);

  const notifications = useSelector(persistentNotificationsSelector);

  return (
    <div>
      <div className="header-container">
        <span className="header">Recent notifications</span>
        <Button
          id="clear-btn"
          className="clear-btn"
          type="button"
          onClick={handleClearNotifications}
          icon={['fas', 'times-circle']}
          label="Clear"
        />
      </div>
      {notifications.length > 0 ? (
        notifications.map(notification => (
          <Notification
            key={notification.created}
            notification={notification}
          />
        ))
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default NotificationContainer;
