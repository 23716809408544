import { put, call } from 'redux-saga/effects';

import * as API from '../api';
import * as actionTypes from 'store/actions/actionTypes';

import {
  showErrorNotification,
  showSuccessNotification
} from './notifications';

import { getUsers as getUsersAction } from 'store/actions/users';

function* getUsers() {
  try {
    const response = yield call(API.fetchUsers);

    yield put({
      type: actionTypes.GET_USERS_SUCCEEDED,
      payload: response.data.response
    });
  } catch (e) {
    yield put({
      type: actionTypes.GET_USERS_FAILED,
      payload: e.message
    });
    yield* showErrorNotification({
      content: 'Something went wrong in getting users'
    });
  }
}

function* deleteUser(action) {
  const { id } = action.payload;
  try {
    const response = yield call(API.deleteUser, { id });

    yield put({
      type: actionTypes.DELETE_USER_SUCCEEDED,
      payload: response.data.response
    });
    yield* showSuccessNotification({ content: 'User deleted.' });
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_USER_FAILED,
      payload: e.message
    });
    yield* showErrorNotification({
      content: 'Error deleting user.'
    });
  } finally {
    yield put(getUsersAction());
  }
}

function* updateUser(action) {
  const { id, data } = action.payload;
  try {
    const response = yield call(API.updateUser, { id, data });

    yield put({
      type: actionTypes.UPDATE_USER_SUCCEEDED,
      payload: response.data.response
    });
    yield* showSuccessNotification({ content: 'Successfully updated user!' });
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_USER_FAILED,
      payload: e.message
    });
    yield* showErrorNotification({
      content: 'Something went wrong, error updating user.'
    });
  } finally {
    yield put(getUsersAction());
  }
}

export { getUsers, deleteUser, updateUser };
