import * as Yup from 'yup';

export const REGISTER_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email address must be valid!')
    .required('Please enter valid email!'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Please enter a password')
});

export const RESET_PASSWORD_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email address must be valid!')
    .required('Please enter valid email!')
});

export const LOGIN_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Please enter an email'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Please enter a password')
});

export const TEMPLATE_FORM_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Please enter a template name'),
  description: Yup.string().required('Please enter a template description')
});

export const FLOW_FORM_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Please enter a flow name')
});

export const getUserValidationSchema = editing => {
  if (editing) {
    return Yup.object().shape({
      email: Yup.string()
        .email()
        .required('Please enter an email'),
      fullName: Yup.string().required('Please enter full name')
    });
  } else {
    return Yup.object().shape({
      email: Yup.string()
        .email()
        .required('Please enter an email'),
      password: Yup.string()
        .required('Please enter a password')
        .min(8, 'Password is too short - should be at least 8 characters long'),
      fullName: Yup.string().required('Please enter full name')
    });
  }
};

export const ACCOUNT_SETTINGS_SCHEMA = Yup.object().shape({
  companyName: Yup.string().required('Please enter a company name'),
  city: Yup.string().required('Please enter a city'),
  companyAddress: Yup.string().required('Please enter a company address'),
  postalCode: Yup.string().required('Please enter a postal code'),
  country: Yup.string().required('Please enter a country'),
  vatNumber: Yup.string().required('Please enter a vat number')
});

export const ADD_USER_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Please enter an email'),
  password: Yup.string()
    .required('Please enter a password')
    .min(8, 'Password is too short - should be at least 8 characters long'),

  fullName: Yup.string().required('Please enter full name')
});

export const SET_PASSWORD = Yup.object().shape({
  password: Yup.string()
    .required('Please enter a password')
    .min(8, 'Password is too short - should be at least 8 characters long')
});

export const isRequired = value => {
  return !value ? `Please insert value` : '';
};
