/* eslint react/jsx-no-bind: [2, {
    "allowArrowFunctions": true
}] */
import React, { useCallback, useEffect } from 'react';
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useRouteMatch
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { install as installGtag } from 'ga-gtag';
import isEqual from 'lodash/isEqual';
import { Layout, AuthorizationLayout } from 'components';

import { ROUTES, CHUNKS, FEATURE_FLAGS } from 'constants/index';
import { loadPersistentState } from 'store/actions/persistentState';
import { requestUserLogout, authCheck } from 'store/actions/auth';
import { evaluateFlag } from 'store/actions/featureFlags';
import { gtagFeatureFlagSelector } from 'store/selectors';

import LoginView from 'containers/Authorization/Login';
import AuthorizationViews from 'views/Authorization';
import AccountDetails from 'containers/AccountDetails';
import Error from 'components/Error/Error';

import unauthorizedInterceptor from 'interceptors/unauthorized';

import './fontawesome';

const App = () => {
  const dispatch = useDispatch();

  const authRoute = useRouteMatch(ROUTES.AUTH.ROOT);

  const { user, inProgress, authenticated, fetched } = useSelector(
    store => store.auth,
    isEqual
  );

  const gtagFeatureFlag = useSelector(gtagFeatureFlagSelector);

  // Load persistent store on application load
  useMount(() => {
    unauthorizedInterceptor(() => dispatch(authCheck()));
    dispatch(loadPersistentState());
    dispatch(evaluateFlag(FEATURE_FLAGS.GTAG));
  });

  useEffect(() => {
    const gtag = gtagFeatureFlag?.variantKey;

    if (gtag && gtag !== 'false') {
      installGtag(gtag);
    }
  }, [gtagFeatureFlag]);

  useEffect(() => {
    // if user tries to access some route for not authorized user
    // but have token in place, we need to log out user
    if (authRoute && authenticated && fetched && !inProgress) {
      dispatch(requestUserLogout());
    }
  }, [authRoute, authenticated, dispatch, fetched, inProgress]);

  const prefetchOnMount = useCallback(() => {
    // if user is authorized preload all pages
    if (authenticated) {
      Object.values(CHUNKS).forEach(chunk => chunk.preload());
    }
  }, [authenticated]);

  // While we don't know auth state of the user
  if (!fetched) {
    return null;
  }

  if (authRoute && authenticated) {
    return null;
  }

  // authenticated
  if (authenticated) {
    return (
      <Layout
        authenticated={authenticated}
        inProgress={inProgress}
        email={user?.email}
        onMount={prefetchOnMount}
      >
        <Switch>
          <Route path={ROUTES.FLOWS.ROOT} component={CHUNKS.FlowsView} />
          <Route
            path={ROUTES.DATA_NODES.ROOT}
            component={CHUNKS.DataNodesView}
          />
          <Route path={ROUTES.ACCOUNT.ROOT} component={CHUNKS.AccountViews} />
          <Route
            path={ROUTES.ROOT}
            exact
            component={() => <Redirect to={ROUTES.FLOWS.ROOT} />}
          />
          <Route
            path={ROUTES.INTEGRATIONS.ROOT}
            component={CHUNKS.IntegrationViews}
          />
          <Route component={() => <Error errorStatus={404} />} />
        </Switch>
      </Layout>
    );
  }

  // not authenticated
  return (
    <AuthorizationLayout>
      <Switch>
        <Route
          path={ROUTES.ACCOUNT.DETAILS.AUTHORIZE}
          component={AccountDetails}
        />
        <Route path={ROUTES.AUTH.ROOT} component={AuthorizationViews} />
        <Route path="*" component={LoginView} />
      </Switch>
    </AuthorizationLayout>
  );
};

export default withRouter(App);
