import isEqual from 'lodash/isEqual';

export function getSelectedDataTypeName(dataType) {
  let selectedDataType;
  switch (dataType) {
    case '0':
      selectedDataType = 'all';
      break;
    case '1':
      selectedDataType = 'text';
      break;
    case '2':
      selectedDataType = 'audio';
      break;
    case '3':
      selectedDataType = 'hsl-mqtt';
      break;
    case '4':
      selectedDataType = 'picture';
      break;
    case '5':
      selectedDataType = 'video';
      break;
    case '6':
      selectedDataType = 'mqtt';
      break;
    case '7':
      selectedDataType = 'video-numeric';
      break;
    case '8':
      selectedDataType = 'numeric';
      break;
    case '9':
      selectedDataType = 'opcua';
      break;
    default:
      break;
  }
  return selectedDataType;
}

export function applyTagFilters({ dataSources, tagFilters }) {
  if (!dataSources || dataSources.length === 0) {
    return [];
  }
  if (!tagFilters || tagFilters.length === 0) {
    return dataSources;
  }
  const tagFiletersMapped = tagFilters.map(f => {
    const [name, value] = f.key.split('.');
    return { name, value };
  });

  if (!tagFiletersMapped.length) return dataSources;

  return dataSources.filter(dataSource => {
    const { tags } = dataSource;

    return tagFiletersMapped.every(({ name, value }) => tags[name] === value);
  });
}

export function composeTags(dataSources) {
  const outputTags = [];

  dataSources.forEach(({ tags }) => {
    Object.entries(tags).forEach(([tagName, tagValue]) => {
      const tag = {
        key: `${tagName}.${tagValue}`,
        name: tagName,
        value: tagValue
      };
      if (!outputTags.find(t => isEqual(t, tag))) {
        outputTags.push(tag);
      }
    });
  });

  return outputTags;
}

export function getDataTypeNames(dataTypes, handleTabClick) {
  const allDataSources = { title: 'all', onClick: handleTabClick };
  const dataTypesNames = Object.values(dataTypes)
    .map(type => {
      if (type.name.includes('unspecified')) {
        return { title: null };
      }
      return { title: type.name, onClick: handleTabClick };
    })
    .filter(function(item) {
      return item.title != null;
    });

  dataTypesNames.unshift(allDataSources);
  return dataTypesNames;
}
