import React, { useCallback, useMemo } from 'react';

import { Slider, Field, FormDropdown } from 'components';
import { getFieldName } from 'utilities';
import { DEFINITIONS_BY_TYPE } from 'mocks/mockIntegrations';

import { isSliderParameter, isEventKeyParameter } from './utils';
import Toggle from './Toggle';

export const PARAMETER_TYPES = {
  boolean: 'boolean'
};

const NodeParameter = ({ parameter, validate, integrationType, ...props }) => {
  const isSlider = isSliderParameter(parameter);
  const isDropdown = parameter.validValues ? true : false;
  const isEventKey = isEventKeyParameter(parameter);

  const isIntegrationField = useMemo(() => {
    const fieldDefinitions = integrationType
      ? DEFINITIONS_BY_TYPE[integrationType.toUpperCase()]
      : null;
    if (fieldDefinitions) {
      if (
        fieldDefinitions.find(
          definition => definition.ID === parameter.nodeTypeParameterID
        )
      ) {
        return true;
      }
    }
    return false;
  }, [integrationType, parameter.nodeTypeParameterID]);

  const handleValidate = useCallback(() => {
    return validate(
      parameter.required,
      props,
      getFieldName(
        parameter.type,
        parameter.ID,
        parameter.flowNodeID,
        parameter.nodeTypeParameterID
      )
    );
  }, [parameter, props, validate]);

  if (isEventKey) {
    return null;
  }

  if (isSlider) {
    return (
      <Slider
        key={parameter.ID}
        name={getFieldName(
          parameter.type,
          parameter.ID,
          parameter.flowNodeID,
          parameter.nodeTypeParameterID
        )}
        label={parameter.name}
        defaultValue={Number(parameter.default)}
        max={Number(parameter.max)}
        min={Number(parameter.min)}
      />
    );
  }

  if (isDropdown) {
    return (
      <div className="field-container">
        <label>{parameter.name}</label>
        <FormDropdown
          key={parameter.ID}
          name={getFieldName(
            parameter.type,
            parameter.ID,
            parameter.flowNodeID,
            parameter.nodeTypeParameterID
          )}
          placeholder="Pick type of tracker to use"
          validate={handleValidate}
          values={parameter.validValues}
          rightIcon="caret-down"
          selected={parameter.value}
        />
      </div>
    );
  }

  switch (parameter.type) {
    case PARAMETER_TYPES.boolean: {
      return (
        <Toggle
          parameter={parameter}
          name={getFieldName(
            parameter.type,
            parameter.ID,
            parameter.flowNodeID,
            parameter.nodeTypeParameterID
          )}
        />
      );
    }

    default:
      return (
        <Field
          key={parameter.ID}
          disabled={isIntegrationField}
          name={getFieldName(
            parameter.type,
            parameter.ID,
            parameter.flowNodeID,
            parameter.nodeTypeParameterID
          )}
          label={`${parameter.name} ${
            isIntegrationField ? '(Integration)' : ''
          }`}
          type={isIntegrationField ? 'password' : parameter.type}
          placeholder={isIntegrationField ? '' : `Enter ${parameter.name}`}
          validate={handleValidate}
        />
      );
  }
};

export default NodeParameter;
