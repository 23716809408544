import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useField } from 'formik';

import useDropdown from 'hooks/useDropdown';
import { Icon, Button } from 'components';
import ClickOutside from 'hoc/ClickOutside/ClickOutside';
import styles from './FormDropdown.module.scss';

const FormDropdown = ({
  values = [],
  placeholder,
  rightIcon,
  selected,
  name
}) => {
  const [toggle, setToggle] = useState('');
  const [field, _, helpers] = useField({ name, value: selected });

  const { shouldShow, setShouldShow } = useDropdown();

  const toggleDropdown = useCallback(() => {
    setShouldShow(!shouldShow);
    setToggle(!shouldShow ? 'toggle' : '');
  }, [shouldShow, setShouldShow]);

  const handleSelect = value => e => {
    e.preventDefault();
    helpers.setValue(value);
    toggleDropdown();
  };

  const headerTitle = field.value ? field.value : placeholder;

  function handleClickOutside() {
    setShouldShow(false);
    setToggle('');
  }

  return (
    <ClickOutside onClickOutside={handleClickOutside}>
      <div className={'dropdown-container form'}>
        <div
          onClick={toggleDropdown}
          className={classnames(styles.dropdownHeader, toggle, {
            'dropdown__not-selected': !selected
          })}
        >
          <Button
            className="dropdown-header-button dropdown"
            type="button"
            aria-haspopup="true"
            aria-expanded={shouldShow}
            id="dropdownMenuButton"
            label={headerTitle}
            title={headerTitle}
          />
          {rightIcon ? (
            <Icon className="dropdown-header-caret" name={['fas', rightIcon]} />
          ) : null}
        </div>
        {shouldShow && (
          <div className="dropdown-list">
            {values.map(item => (
              <input
                className={styles.input}
                key={item}
                onClick={handleSelect(item)}
                title={item}
                value={item}
                spellCheck="false"
              />
            ))}
          </div>
        )}
      </div>
    </ClickOutside>
  );
};

FormDropdown.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  rightIcon: PropTypes.string,
  selected: PropTypes.string,
  name: PropTypes.string
};

export default FormDropdown;
