/* eslint react/jsx-no-bind: [2, {
    "allowArrowFunctions": true
}] */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tab, Tabs as RTabs, TabList, TabPanel } from 'react-tabs';

import noop from 'utilities/noop';

import './Tabs.scss';

const Tabs = ({
  isVertical,
  className,
  tabs,
  panels,
  selectedTab,
  onSelect
}) => {
  const classes = classNames(
    isVertical ? 'react-tabs-vertical' : 'react-tabs',
    className
  );

  let generatedTabs = null;
  let generatedPanels = null;

  if (tabs) {
    let index = 0;
    generatedTabs = tabs.map(tab => {
      const currentTabIndex = index;
      return (
        <Tab
          key={index++}
          disabled={tab.onClick ? true : false}
          onClick={() => (tab.onClick ? tab.onClick(currentTabIndex) : null)}
        >
          <i className={tab.iconClass}>{tab.icon}</i>
          {tab.title}
        </Tab>
      );
    });
  }

  if (panels) {
    let index = 0;
    generatedPanels = panels.map(panel => {
      return <TabPanel key={index++}>{panel}</TabPanel>;
    });
  }

  if (isVertical) {
    return (
      <RTabs
        onSelect={onSelect}
        selectedIndex={selectedTab}
        className={classes}
      >
        <div className="flex-row">
          <div className="nav">
            <TabList>{generatedTabs}</TabList>
          </div>
          <div>{generatedPanels}</div>
        </div>
      </RTabs>
    );
  }

  return (
    <RTabs onSelect={onSelect} selectedIndex={selectedTab} className={classes}>
      <TabList>{generatedTabs}</TabList>
      {generatedPanels}
    </RTabs>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOf([PropTypes.string, PropTypes.element]).required,
      icon: PropTypes.object, //eslint-disable-line
      iconClass: PropTypes.string
    })
  ),
  panels: PropTypes.node,
  selectedTab: PropTypes.number,
  onSelect: PropTypes.func
};

Tabs.defaultProps = {
  onSelect: noop
};

export default Tabs;
