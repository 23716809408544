import { createSelector } from 'reselect';

// createSelector will avoid re-selection from redux store if component is rerendered
export const flowsSelector = createSelector(
  store => store.flows.flows,
  flows => flows
);

export const flowSelector = createSelector(
  store => store.flows.flow,
  flow => flow
);

export const nodeTypesSelector = createSelector(
  store => store.flows.nodeTypes,
  nodeTypes => nodeTypes
);

export const flowParametersSelector = createSelector(
  flowSelector,
  flow => flow.parameters
);
