import { format } from 'date-fns';
import { chartColors } from './transform';

export const generateChartData = data => {
  if (!data) return [];

  return data.map(({ key, value }) => {
    return { x: key, y: value };
  });
};

export const generateTimeSeriesData = data => {
  if (!data) return [];

  return data.map(({ key, value, timeStamp }) => {
    // d.M.YYYY HH:mm:ss
    return { x: format(timeStamp, 'HH:mm:ss'), y: value, label: key };
  });
};

export const convertGraphDataToObject = data => {
  try {
    const parsedData = JSON.parse(data);
    if (parsedData.graphs) {
      return parsedData;
    }
    throw new Error();
  } catch {
    return {
      graphs: {
        lineGraph: false,
        barGraph: false,
        areaGraph: false,
        treeMapDiagram: false,
        stackedBarGraph: false,
        stackedAreaGraph: false,
        pieChart: false,
        donutChart: false
      }
    };
  }
};

export const generateMultipleChartData = data => {
  if (!data || !Array.isArray(data)) return [];
  const processedObjects = {};

  // Sum items by key
  data.reduce((dataItems, { key, value }) => {
    if (processedObjects[key]) {
      processedObjects[key] = processedObjects[key] + value;
    } else {
      processedObjects[key] = value;
    }
    return dataItems;
  }, []);

  const chartData = [];
  for (const [key, value] of Object.entries(processedObjects)) {
    chartData.push({
      x: key,
      y: value
    });
  }

  return chartData;
};

const pickedColors = {};
// generates data out of store.persistentItems remembering color set for each key
export const generateStackedAreaChartData = data => {
  if (!data) return [];
  // temp object to store collection values
  const temp = {};

  data.forEach(({ key, value, timeStamp }) => {
    if (!temp[key]) {
      temp[key] = [];
    }
    temp[key].push({
      x: timeStamp,
      y: value
    });
  });

  // eslint-disable-next-line
  let counter = 0;

  const graphValues = Object.entries(temp).map(([name, data]) => {
    if (!pickedColors[name]) {
      pickedColors[name] = chartColors.find(
        c => Object.values(pickedColors).indexOf(c) === -1
      );
    }
    counter += 1;
    return {
      data,
      color: pickedColors[name]
    };
  });

  return graphValues;
};
