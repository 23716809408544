import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import ReactSlider from 'react-slider';
import { Input } from 'components';
import './Slider.scss';

const Slider = ({ className, name, label, max, min, value }) => {
  const classes = classNames(className || 'field-container');
  const [field, _, helpers] = useField({ name, type: 'text', value });

  const handleValueChange = event => {
    helpers.setValue(event.target.value);
  };

  // resize ref when the parent component is resizing independently
  const containerRef = useRef();
  const sliderRef = useRef();
  useEffect(() => {
    if (typeof ResizeObserver !== 'undefined') {
      const currentRef = containerRef.current;
      const resizeObserver = new ResizeObserver(() => {
        sliderRef.current.resize();
      });
      resizeObserver.observe(currentRef);

      return () => {
        resizeObserver.unobserve(currentRef);
      };
    }
  }, []);
  return (
    <div className={classes} ref={containerRef}>
      <label htmlFor={name}>
        {label || name} ({min}—{max})
      </label>
      <div className="slider">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="thumb"
          trackClassName="track"
          max={max}
          min={min}
          step={!Number.isInteger(min) || Number(max) <= 1 ? 0.01 : 1}
          ref={sliderRef}
          type="number"
          {...field}
          value={+field.value}
          // eslint-disable-next-line
          onChange={value => helpers.setValue(`${value}`)}
        />
        <Input
          className="slider-value"
          type="number"
          placeholder="Value"
          value={field.value}
          onChange={handleValueChange} /* eslint-disable-line */
          min={min}
          max={max}
          step="0.01"
        />
      </div>
      <ErrorMessage component="span" className="error" name={name} />
    </div>
  );
};

Slider.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  thumbClassName: PropTypes.string,
  trackClassName: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  type: PropTypes.number
};

export default Slider;
