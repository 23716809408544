import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Checkbox.scss';

const Checkbox = ({ ...props }) => {
  const classes = classNames([props.className, 'checkbox']);
  const label = props?.label;

  return (
    <label className="checkbox-container">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
        id={props.id}
        name={props.name}
        {...props}
        className={classes}
      ></input>
      <span className="checkmark"></span>
    </label>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool
};

export default Checkbox;
