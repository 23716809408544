import React, { useState, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import isEqual from 'lodash/isEqual';

import { Button, Field, Dropdown, RadioGroup } from 'components';
import { createFlow, updateFlow } from 'store/actions/flow';
import { cloneFlow } from 'store/actions/flows';
import { closeRightPanel } from 'store/actions/panels';
import { flowsSelector } from 'store/selectors';

import { FLOW_FORM_SCHEMA } from 'schema/schema';
import { ROUTES } from 'constants';

import './AddFlow.scss';

const AddFlow = props => {
  const dispatch = useDispatch();

  const flowId = props.flowId;
  const name = props.flowName;
  const description = props.flowDescription;

  let initialFormValues = {
    name: name ? name : '',
    description: description ? description : ''
  };

  const [selectedFlowId, setSelectedFlowId] = useState();
  const [isBlankEditorChecked, setIsBlankEditorChecked] = useState(true);

  const flows = useSelector(flowsSelector, isEqual);

  const selectedFlow = useMemo(
    () => flows.find(flow => flow.ID === selectedFlowId),
    [flows, selectedFlowId]
  );

  const dropdownData = useMemo(
    () =>
      flows.map(flow => {
        return {
          id: flow.ID,
          value: flow.name,
          key: flow.ID,
          status: flow.status
        };
      }),
    [flows]
  );

  const closePanel = useCallback(() => {
    dispatch(closeRightPanel());
  }, [dispatch]);

  const handleCloneFlow = useCallback(
    (data, flow) => {
      dispatch(cloneFlow(flow.ID, data.name, data.description));
      props.history.push({ pathname: ROUTES.FLOWS.ROOT });
    },
    [dispatch, props.history]
  );

  const onSubmit = data => {
    if (selectedFlowId && !isBlankEditorChecked) {
      handleCloneFlow(data, selectedFlow);
      closePanel();
    } else {
      const flow = {
        name: data.name,
        description: data.description,
        flowId
      };
      const upsertAction = flowId ? updateFlow : createFlow;
      dispatch(upsertAction(flow));
    }
  };

  function onFlowSelected(value) {
    setSelectedFlowId(value.id);
  }

  function handleRadioChange(evt) {
    evt.stopPropagation();
    evt.target.blur();

    const target = evt.target;
    const value = target.value;

    if (value === 'blankEditor') {
      setIsBlankEditorChecked(true);
    } else {
      setIsBlankEditorChecked(false);
    }
  }

  const buttons = (
    <React.Fragment>
      <Button
        id="cancel-btn"
        type="button"
        onClick={closePanel}
        label="Cancel"
      />
      <Button id="save-btn" type="submit" className="primary" label="Save" />
    </React.Fragment>
  );

  return (
    <div className="add-flow">
      <div className="right-panel-header">
        <h1>{flowId ? 'Edit Flow' : 'Add Flow'}</h1>
        <Button className="icon" onClick={closePanel} icon={['fal', 'times']} />
      </div>
      <div className="form">
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validationSchema={FLOW_FORM_SCHEMA}
          render={() => {
            return (
              <Form>
                <Field
                  key="flowName"
                  name="name"
                  label="Flow Name"
                  type="text"
                  placeholder={'Enter Flow Name'}
                />

                <Field
                  name="description"
                  label="Description"
                  component="textarea"
                  placeholder={'Enter Description'}
                />
                {!flowId ? (
                  <RadioGroup
                    name="createFlowFrom"
                    label="Create Flow From"
                    onChange={handleRadioChange}
                    inputs={[
                      {
                        id: 'blankEditor',
                        label: 'Blank editor',
                        value: 'blankEditor',
                        checked: isBlankEditorChecked
                      },
                      {
                        id: 'clone-flow',
                        label: 'Clone Flow',
                        value: 'cloneFlow',
                        component: (
                          <Dropdown
                            values={dropdownData.sort((a, b) =>
                              a.value > b.value ? 1 : -1
                            )}
                            onItemSelected={onFlowSelected}
                            placeholder="Clone a pre-existing configuration"
                            disabled={isBlankEditorChecked}
                            rightIcon="caret-down"
                            showStatus
                          />
                        )
                      }
                    ]}
                  />
                ) : null}
                <div className="options-bar">{buttons}</div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(AddFlow);
