import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { chartColors } from 'utilities/transform';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  VerticalGridLines,
  HorizontalGridLines
} from 'react-vis';

import { WidgetWrapper, NoData } from 'components';

const BarChart = ({ className, data, title, name }) => {
  const classes = classNames(className || 'bar-chart');

  const items = data?.items && Array.isArray(data?.items) ? data?.items : [];
  const domainMin = 0;
  const chartHeight = 400;

  const [maxYDomain, setMaxYDomain] = useState(20);

  const chart = items.map(
    (item, index) =>
      (data = {
        x: item.key,
        y: item.value,
        color: chartColors[Math.floor(index % chartColors.length)]
      })
  );

  useEffect(() => {
    const currentMaxYDomain = Math.max(...chart.map(({ y }) => (y ? y : 0)));

    if (maxYDomain - 10 <= currentMaxYDomain && currentMaxYDomain > 6) {
      setMaxYDomain(currentMaxYDomain * 1.5);
    }
  }, [chart, maxYDomain]);

  return (
    <WidgetWrapper title={title} name={name}>
      {chart.length > 0 ? (
        <div className={classes}>
          <FlexibleWidthXYPlot
            xType="ordinal"
            colorType="literal"
            height={chartHeight}
            margin={{ left: maxYDomain > 1000 ? 70 : 40 }}
            yDomain={[domainMin, maxYDomain]}
          >
            <VerticalGridLines tickValues={[0]} tickTotal={1} />
            <HorizontalGridLines />
            <XAxis orientation="bottom" />
            <YAxis tickTotal={10} />
            <VerticalBarSeries data={chart} />
          </FlexibleWidthXYPlot>
        </div>
      ) : (
        <NoData />
      )}
    </WidgetWrapper>
  );
};

BarChart.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      y: PropTypes.number,
      x: PropTypes.string,
      color: PropTypes.string
    })
  )
};

export default BarChart;
