const ROUTING_CONSTANTS = {
  USERS: 'users',
  SETTINGS: 'settings',
  SOURCES_LIST: 'sources-list',
  MAP: 'map'
};

const ROUTES = {
  FLOWS: {
    ROOT: '/flows',
    FLOW: {
      ROOT: '/flows/:id/:tab'
    }
  },
  DATA_NODES: {
    ROOT: '/data-nodes',
    DATA_NODE_DETAILS: {
      ROOT: '/data-nodes/:dataNodeId/:dataType/:tab?',
      SOURCES_LIST: `/data-nodes/:dataNodeId/:dataType/${ROUTING_CONSTANTS.SOURCES_LIST}`,
      MAP: `/data-nodes/:dataNodeId/:dataType/${ROUTING_CONSTANTS.MAP}`
    }
  },
  ACCOUNT: {
    ROOT: '/account',
    DETAILS: {
      ROOT: '/account/:tab?',
      SETTINGS: `/account/${ROUTING_CONSTANTS.SETTINGS}`,
      USERS: `/account/${ROUTING_CONSTANTS.USERS}`,
      AUTHORIZE: '/account/:tab/:jwt'
    }
  },
  AUTH: {
    ROOT: '/auth',
    PASSWORD: {
      SET_NEW: '/auth/password/:jwt',
      SET_INVITED: '/auth/invited/:jwt',
      RESET: '/auth/password/forgot',
      ROOT: '/auth'
    }
  },
  INTEGRATIONS: {
    ROOT: '/integrations'
  },
  ROOT: '/'
};

export { ROUTING_CONSTANTS, ROUTES };
