import { ReactComponent as TwitterIcon } from 'assets/vector/icon-twitter.svg';
import { ReactComponent as SmsIcon } from 'assets/vector/icon-sms.svg';
import { ReactComponent as PowerBiIcon } from 'assets/vector/icon-power-bi.svg';
import { ReactComponent as SlackIcon } from 'assets/vector/icon-slack.svg';

export const INTEGRATION_TYPES = {
  TWITTER: 'TWITTER',
  SMS: 'SMS',
  POWERBI: 'POWERBI',
  SLACK: 'SLACK'
};

export const ICONS_BY_TYPE = {
  [INTEGRATION_TYPES.TWITTER]: TwitterIcon,
  [INTEGRATION_TYPES.SMS]: SmsIcon,
  [INTEGRATION_TYPES.POWERBI]: PowerBiIcon,
  [INTEGRATION_TYPES.SLACK]: SlackIcon
};

export const INTEGRATION_OPTIONS = [
  {
    name: 'Twitter',
    type: INTEGRATION_TYPES.TWITTER,
    icon: ICONS_BY_TYPE[INTEGRATION_TYPES.TWITTER]
  },
  {
    name: 'SMS',
    type: INTEGRATION_TYPES.SMS,
    icon: ICONS_BY_TYPE[INTEGRATION_TYPES.SMS]
  },
  {
    name: 'PowerBI',
    type: INTEGRATION_TYPES.POWERBI,
    icon: ICONS_BY_TYPE[INTEGRATION_TYPES.POWERBI]
  },
  {
    name: 'Slack',
    type: INTEGRATION_TYPES.SLACK,
    icon: ICONS_BY_TYPE[INTEGRATION_TYPES.SLACK]
  }
];

export const INTEGRATION_TITLE_DEFINITION = {
  name: 'title',
  ID: 'INTEGRATION_TITLE',
  label: 'Title',
  type: 'text',
  default: '',
  max: 16,
  min: 3,
  required: true
};

export const TYPE_LABELS_BY_INTEGRATION_TYPE = {
  [INTEGRATION_TYPES.TWITTER]: 'Operational',
  [INTEGRATION_TYPES.SMS]: 'Operational',
  [INTEGRATION_TYPES.POWERBI]: 'Operational',
  [INTEGRATION_TYPES.SLACK]: 'Operational'
};

export const DEFINITIONS_BY_TYPE = {
  [INTEGRATION_TYPES.TWITTER]: [
    {
      ID: '0a7fbf4f-97b1-4fa6-b95a-137ee641985c',
      nodeTypeID: 'eb27d02e-76c9-437e-8592-e7c7a26945a1',
      name: 'Twitter consumer key',
      variable: 'TWITTER_CONSUMER_KEY',
      description: null,
      type: 'text',
      default: '',
      min: null,
      max: null,
      required: true
    },
    {
      ID: 'eb27d02e-76c9-437e-8592-e7c7a26945a1',
      nodeTypeID: 'eb27d02e-76c9-437e-8592-e7c7a26945a1',
      name: 'Twitter access secret',
      variable: 'TWITTER_ACCESS_SECRET',
      description:
        'This token represents the Twitter account that owns the App, and allows you to make a request on behalf of that Twitter account.',
      type: 'text',
      default: '',
      min: null,
      max: null,
      required: true
    },
    {
      ID: 'cb7ed3d2-cfd6-4e2a-9a2b-94a647c09157',
      nodeTypeID: 'eb27d02e-76c9-437e-8592-e7c7a26945a1',
      name: 'Twitter access token',
      variable: 'TWITTER_ACCESS_TOKEN',
      description:
        'This token also represents the Twitter account that owns the App, and allows you to make a request on behalf of that Twitter account.',
      type: 'text',
      default: '',
      min: null,
      max: null,
      required: true
    },
    {
      ID: '72e66746-c98b-4bc9-a0aa-a00b969d7a28',
      nodeTypeID: 'eb27d02e-76c9-437e-8592-e7c7a26945a1',
      name: 'Twitter consumer secret',
      variable: 'TWITTER_CONSUMER_SECRET',
      description:
        'This is a password, and allows you to make a request on behalf of your App.',
      type: 'text',
      default: '',
      min: null,
      max: null,
      required: true
    }
  ],
  [INTEGRATION_TYPES.SMS]: [
    {
      ID: 'd1592de9-2215-44a0-a373-f0fc36998d6a',
      nodeTypeID: '9f03a8d9-b68a-4d1f-9fa9-bedc538ded57',
      name: 'Siru SMS Service Username',
      variable: 'SMS_SIRUSMSUSERNAME',
      type: 'text',
      default: '',
      description: null,
      min: null,
      max: null,
      required: true
    },
    {
      ID: 'e7c8f7ca-c5c9-45e1-abd3-e70a44ce2a17',
      nodeTypeID: '9f03a8d9-b68a-4d1f-9fa9-bedc538ded57',
      name: 'Siru SMS Service Password',
      variable: 'SMS_SIRUSMSPASSWORD',
      type: 'text',
      default: '',
      min: null,
      max: null,
      required: true
    }
  ],
  [INTEGRATION_TYPES.POWERBI]: [
    {
      ID: '4987b52a-75fa-442e-bfbc-34137fa8b381',
      nodeTypeID: '1c82aec3-c84f-4ffe-91c8-aeba28e65f48',
      name: 'Azure Client ID',
      variable: 'AZURE_CLIENT_ID',
      type: 'text',
      default: '',
      min: null,
      max: null,
      required: false
    },
    {
      ID: 'c3866ae9-86c5-495a-94f2-fa0e0793db27',
      nodeTypeID: '1c82aec3-c84f-4ffe-91c8-aeba28e65f48',
      name: 'Azure Tenant ID',
      variable: 'AZURE_TENANT_ID',
      type: 'text',
      default: '',
      min: null,
      max: null,
      required: true
    },
    {
      ID: 'e06482ed-4f05-46ad-a4ea-1a0138882339',
      nodeTypeID: '1c82aec3-c84f-4ffe-91c8-aeba28e65f48',
      name: 'Azure Client Secret',
      variable: 'AZURE_CLIENT_SECRET',
      type: 'text',
      default: '',
      min: null,
      max: null,
      required: false
    }
  ],
  [INTEGRATION_TYPES.SLACK]: [
    {
      ID: '5a53d8d9-d608-4cbc-a7bf-cb5b16a72d99',
      nodeTypeID: '96927870-ff50-47b9-907e-dd08859e0212',
      name: 'Slack bot user OAuth token',
      variable: 'SLACK_TOKEN',
      type: 'text',
      default: 'xoxb-',
      min: null,
      max: null,
      required: true
    },
    {
      ID: '3b3f17e2-d5bc-45ed-b6a8-aa4da0276c32',
      nodeTypeID: '96927870-ff50-47b9-907e-dd08859e0212',
      name: 'Slack channel id',
      variable: 'SLACK_CHANNEL',
      type: 'text',
      default: 'C0XXXXXXXXX',
      min: null,
      max: null,
      required: true
    }
  ]
};
