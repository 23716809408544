import * as compareData from './CompareParameterInputs';

export default type => {
  switch (type) {
    case 'COMPARE': {
      return compareData;
    }
    default:
      return null;
  }
};
