export const isSliderParameter = ({ min, max }) => {
  // min and max exist in parameter
  if (min && max) {
    if (max !== '' && min !== '') {
      return true;
    }
  }
  return false;
};

export const isEventKeyParameter = parameter => {
  if (
    parameter.variable === 'EVENT_KEY' ||
    parameter.variable === 'OUTPUT_KEY' ||
    parameter.variable === 'INPUT_KEY'
  ) {
    return true;
  }
  return false;
};
