import React from 'react';
import PropTypes from 'prop-types';
import { TimeSeriesLineChart, NoData, WidgetWrapper } from 'components';

import { extractItemsData, limitDatapointBufferSize } from './convert';

const TimeSeriesDataView = ({
  state,
  title,
  datapointsLowerLimit,
  timewindowLowerLimit
}) => {
  const dataSet = extractItemsData(state);
  const limitedDataSet = dataSet.map(lineseries =>
    limitDatapointBufferSize(
      lineseries,
      +datapointsLowerLimit,
      +timewindowLowerLimit
    )
  );

  return (
    <WidgetWrapper title={title} name="Time Series Data">
      {limitedDataSet && limitedDataSet.length > 0 ? (
        <TimeSeriesLineChart data={limitedDataSet} />
      ) : (
        <NoData />
      )}
    </WidgetWrapper>
  );
};

TimeSeriesDataView.propTypes = {
  eventKey: PropTypes.string
};

export default TimeSeriesDataView;
