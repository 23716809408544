import React, { useCallback } from 'react';
import { useMount } from 'react-use';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import {
  authLoadersSelector,
  registrationFeatureFlagSelector
} from 'store/selectors';
import { evaluateFlag } from 'store/actions/featureFlags';
import { requestUserRegister, requestUserLogin } from 'store/actions/auth';
import { Tabs } from 'components';

import { FEATURE_FLAGS } from 'constants/featureFlags';

import { ROUTES } from 'constants';

import LoginForm from './LoginForm';
import HubspotForm from './HubspotForm';
import RegistrationForm from './RegistrationForm';

import './Login.scss';

const ResetPassword = ({ history }) => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(evaluateFlag(FEATURE_FLAGS.REGISTRATION));
  });

  const isLoading = useSelector(authLoadersSelector, isEqual);
  const isAuthenticated = useSelector(state => state.auth.authenticated);
  const registrationFeatureFlag = useSelector(registrationFeatureFlagSelector);
  const shouldShowRegistration =
    registrationFeatureFlag?.variantKey === 'enabled';

  const handleSignIn = useCallback(data => dispatch(requestUserLogin(data)), [
    dispatch
  ]);

  const handleRegister = useCallback(
    data => {
      dispatch(requestUserRegister(data));
    },
    [dispatch]
  );

  const handleForgotPassword = useCallback(() => {
    history.push({ pathname: ROUTES.AUTH.PASSWORD.RESET });
  }, [history]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const tabs = shouldShowRegistration
    ? [{ title: 'Sign in' }, { title: 'Register' }]
    : [{ title: 'Sign in' }];

  const panels = shouldShowRegistration
    ? [
        <LoginForm
          onSubmit={handleSignIn}
          inProgress={isLoading}
          handleForgotPassword={handleForgotPassword}
        />,
        <RegistrationForm onSubmit={handleRegister} inProgress={isLoading} />
      ]
    : [
        <LoginForm
          onSubmit={handleSignIn}
          inProgress={isLoading}
          handleForgotPassword={handleForgotPassword}
        />
      ];

  return (
    <div className="login">
      <div className="login-limited">
        <h2>Sign up</h2>
        <p>Currently we are taking in new users in limited numbers.</p>
        <p>
          Submit your email address if you’re interested in trying out RAIN. You
          will receive a confirmation of your queue status.
        </p>
        <HubspotForm />
      </div>
      <Tabs tabs={tabs} panels={panels} />
    </div>
  );
};

export default ResetPassword;
