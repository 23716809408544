import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import './NoData.scss';

const NoData = props => {
  const classes = classNames(props.className || 'no-data');

  return (
    <div className={classes}>
      <Icon className="icon" name={['fal', 'empty-set']} />
      <p>No data found</p>
    </div>
  );
};

NoData.propTypes = {
  className: PropTypes.string
};

export default NoData;
