import { put, call } from 'redux-saga/effects';

import * as actionTypes from 'store/actions/actionTypes';
import {
  PERSISTENT_INTEGRATIONS,
  addPersistentStateItem,
  removePersistentStateItems
} from 'store/actions/persistentState';

import {
  showErrorNotification,
  showSuccessNotification
} from './notifications';

import { PERSISTENT_STATE_KEY } from 'constants';
import { getItem, setItem } from 'utilities/localStorage';

import nanoid from 'nanoid';
import clone from 'lodash/clone';

function* createIntegrationCredentials(action) {
  const { data } = action.payload;

  if (data) {
    try {
      const integrationCredentials = {
        ID: `${nanoid(12)}`,
        title: data.title,
        type: data.type,
        values: data.values,
        updated: new Date().getTime()
      };

      yield put(
        addPersistentStateItem(PERSISTENT_INTEGRATIONS, integrationCredentials)
      );

      yield put({
        type: actionTypes.CREATE_INTEGRATION_CREDENTIALS_SUCCEEDED,
        payload: integrationCredentials
      });

      yield* showSuccessNotification({
        content: 'Integration credentials created.'
      });
    } catch (error) {
      yield put({
        type: actionTypes.CREATE_INTEGRATION_CREDENTIALS_FAILED,
        payload: error
      });

      yield* showErrorNotification({
        content: 'Error creating integration credentials.'
      });
    }
  }
}

function* updateIntegrationCredentials(action) {
  const { data } = action.payload;

  if (data) {
    try {
      // For integrations credentials implementation, updating existing object in persistent state

      const integrationCredentials = {
        ID: data.ID,
        title: data.title,
        type: data.type,
        values: data.values,
        updated: new Date().getTime()
      };

      const persistentData = yield call(getItem, PERSISTENT_STATE_KEY);

      const processedData = yield call(
        clone,
        yield call(JSON.parse, persistentData)
      );

      const updatedData = processedData.integrations.filter(
        item => item.ID !== data.ID
      );

      processedData.integrations = [...updatedData, integrationCredentials];

      yield* showSuccessNotification({
        content: 'Integration credentials updated.'
      });

      yield call(setItem, PERSISTENT_STATE_KEY, JSON.stringify(processedData));

      yield put({
        type: actionTypes.UPDATE_INTEGRATION_CREDENTIALS_SUCCEEDED,
        payload: integrationCredentials
      });
    } catch (error) {
      yield* showErrorNotification({
        content: 'Error updating credentials.'
      });
      yield put({
        type: actionTypes.UPDATE_INTEGRATION_CREDENTIALS_FAILED,
        payload: error
      });
    }
  }
}

function* deleteIntegrationCredentials(action) {
  const id = action.payload;

  if (id) {
    try {
      yield* showSuccessNotification({
        content: 'Integration credentials deleted.'
      });

      yield put(
        removePersistentStateItems({
          stateKey: PERSISTENT_INTEGRATIONS,
          property: 'ID',
          value: id
        })
      );

      yield put({
        type: actionTypes.DELETE_INTEGRATION_CREDENTIALS_SUCCEEDED,
        payload: id
      });
    } catch (error) {
      yield* showErrorNotification({
        content: 'Error deleting integration credentials.'
      });
      yield put({
        type: actionTypes.DELETE_INTEGRATION_CREDENTIALS_FAILED,
        payload: error
      });
    }
  }
}

export {
  createIntegrationCredentials,
  updateIntegrationCredentials,
  deleteIntegrationCredentials
};
