import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Icon } from 'components';

import Logo from './Logo/Logo';
import Links from './Links/Links';

import { ROUTES } from 'constants/routes';

import './Toolbar.scss';

const Toolbar = ({
  authenticated,
  email,
  onLogout,
  openNotifications,
  withHelp
}) => {
  let links = [];
  let profileLinks = [];
  let rowIndex = 1;

  links = [
    {
      text: 'Flows',
      href: '/flows',
      type: 'navLink',
      id: 'toolbar-overview',
      icon: ['far', 'random'],
      key: rowIndex++
    },
    {
      text: 'Data Nodes',
      href: '/data-nodes',
      type: 'navLink',
      id: 'toolbar-data-nodes',
      icon: ['far', 'arrow-to-right'],
      key: rowIndex++
    },
    {
      text: 'Integrations',
      href: '/integrations',
      type: 'navLink',
      id: 'toolbar-integrations',
      icon: ['far', 'code'],
      key: rowIndex++
    }
  ];

  if (authenticated) {
    profileLinks = [
      {
        text: '',
        type: 'method',
        onClick: () => openNotifications(),
        id: 'toolbar-notifications',
        icon: ['far', 'bell'],
        key: rowIndex++
      },
      {
        text: '',
        href: '/account/users',
        type: 'navLink',
        id: 'toolbar-users',
        icon: ['far', 'users'],
        key: rowIndex++
      },
      {
        text: '',
        type: 'method',
        onClick: () => onLogout(),
        id: 'toolbar-logout',
        icon: ['far', 'sign-out'],
        key: rowIndex++
      }
    ];
  }

  return (
    <nav className="toolbar">
      <div className="toolbar-navigation">
        <Logo />
        {authenticated ? <Links links={links} /> : false}
      </div>
      {authenticated ? (
        <div className="toolbar-user">
          <span className="logged-in-as">
            Logged in as{' '}
            <Link
              to={ROUTES.ACCOUNT.DETAILS.SETTINGS}
              className="toolbar-account"
            >
              {email}
            </Link>
          </span>
          <Links links={profileLinks} />
        </div>
      ) : null}
      {withHelp ? (
        <Link to="#help" className="toolbar-help-block">
          <Icon className="icon" name={['far', 'question-circle']} />
          <span>Help</span>
        </Link>
      ) : null}
    </nav>
  );
};

Toolbar.propTypes = {
  authenticated: PropTypes.bool,
  withHelp: PropTypes.bool,
  email: PropTypes.string,
  onLogout: PropTypes.func,
  openNotifications: PropTypes.func
};

export default Toolbar;
