import React from 'react';
import { RadialChart } from 'react-vis';

import { NoData, WidgetWrapper } from 'components';
import { chartColors } from 'utilities/transform';

import styles from './PieChart.module.scss';

const PieChart = ({ title, name, data, innerRadius, padAngle, radius }) => {
  const items = data?.items ?? [];
  const shouldShow = items.length > 0;

  const total = items.reduce((acc, curr) => {
    return acc + curr?.value;
  }, 0);

  const pieChartData = items.map((item, index) => ({
    angle: item?.value,
    label: item?.key,
    color: chartColors[Math.floor(index % items.length)]
  }));

  return (
    <WidgetWrapper
      title={title}
      name={name}
      style={{ flexGrow: shouldShow ? 2 : 0 }}
    >
      {shouldShow ? (
        <div className={styles.container}>
          <RadialChart
            colorType="literal"
            stroke={'white'}
            data={pieChartData}
            width={300}
            height={300}
            innerRadius={innerRadius}
            padAngle={padAngle}
            radius={radius}
          />
          <div className={styles.keyStats}>
            <div className="label">Key stats & legend</div>
            <div className={styles.keyStatItems}>
              {pieChartData.map(item => (
                <div className={styles.keyStatItem} key={item.label}>
                  <div className={styles.keyStatPercentage}>
                    {`${((item.angle / total) * 100).toFixed(0)}%`}
                  </div>
                  <div className={styles.keyStatLabel}>{item.label}</div>
                  <div
                    className={styles.keyStatBar}
                    style={{ backgroundColor: item.color }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </WidgetWrapper>
  );
};

export default PieChart;
