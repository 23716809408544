import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'components';
import styles from './Card.module.scss';

const Card = props => {
  const part = props.part;

  return (
    <div className={classNames(props.className, styles.cardContainer)}>
      <div
        className={classNames(styles.cardStatusStrip, styles[props.status])}
      />
      <div className={styles.dataCardStats}>
        <div className={styles.dataCardPart}>
          <span>{part}</span>
          <span>{props.partLabel}</span>
        </div>
        <div className={styles.statsSeparator}></div>
        <div className={styles.dataCardTotal}>
          <span>{props.total}</span>
          <span>{props.totalLabel}</span>
        </div>
      </div>
      <div className={styles.dataCardNode}>
        <span>{props.nodeInfo}</span>
        <span>{props.lastEdited}</span>
      </div>
      <div className={styles.cardActionButtons}>
        <div>
          <Button className="icon primary" icon={['far', 'pencil']} />
          <Button className="icon primary" icon={['far', 'clone']} />
          <Button className="icon primary danger" icon={['far', 'trash-alt']} />
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  part: PropTypes.string,
  partLabel: PropTypes.string,
  total: PropTypes.number,
  totalLabel: PropTypes.string,
  nodeInfo: PropTypes.string,
  lastEdited: PropTypes.string
};

export default Card;
