import React, { useCallback, useState, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import isEqual from 'lodash/isEqual';
import { format } from 'date-fns';

import { Input, Button, Table, EditUserForm, Icon } from 'components';

import ModalContainer from 'containers/Modal/ModalContainer';
import { getUsers, deleteUser, updateUser } from 'store/actions/users';
import { hideModal } from 'store/actions/modal';
import { isAdminSelector, authUserId } from 'store/selectors';
import * as actionTypes from 'store/actions/actionTypes';

import styles from './Users.module.scss';

const Users = () => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const isUserAdmin = useSelector(isAdminSelector, isEqual);
  const currentUserId = useSelector(authUserId, isEqual);
  const users = useSelector(state => state.users.users, isEqual);

  const [search, setSearch] = useState('');

  const onSearch = useCallback(event => {
    setSearch(event.target.value);
  }, []);

  const closeModals = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  const openEdit = useCallback(
    row => {
      const userData = row?.original;

      dispatch({
        type: actionTypes.CREATE_MODAL,
        meta: {
          type: 'add',
          entity: 'user',
          data: userData,
          node: (
            <EditUserForm
              id={userData?.id}
              isEditing={!!userData?.id}
              user={userData}
              shouldShowAdminSwitch={!isUserAdmin}
              handleCancel={closeModals}
              isToggleDisabled={currentUserId === userData.id ? true : false}
            />
          ),
          onConfirm: (user, id) => {
            dispatch(updateUser(id, user));
            closeModals();
          }
        }
      });
    },
    [dispatch, closeModals, isUserAdmin, currentUserId]
  );

  const openDelete = useCallback(
    row => {
      const userData = row?.original;

      if (!userData) {
        return;
      }

      dispatch({
        type: actionTypes.CREATE_MODAL,
        meta: {
          type: 'delete',
          entity: 'user',
          id: userData.id,
          data: userData,
          onConfirm: id => {
            if (id) {
              dispatch(deleteUser(id));
            }
          }
        }
      });
    },
    [dispatch]
  );

  const columns = useMemo(
    () =>
      generateColumns({
        handleDelete: openDelete,
        handleEdit: openEdit,
        isAdmin: isUserAdmin,
        userId: currentUserId
      }),
    [isUserAdmin, currentUserId, openDelete, openEdit]
  );

  return (
    <div className={styles.usersContainer}>
      <div className="table-wrapper">
        <div className="table-toolbar">
          <Input
            type="search"
            placeholder="Search"
            onChange={onSearch}
            value={search}
          />
        </div>
        <Table
          data={users}
          search={search}
          columns={columns}
          minRows={5}
          pageSize={5}
          pagination
        />
      </div>
      <ModalContainer closeModal={closeModals} />
    </div>
  );
};

export default Users;

const generateColumns = ({ handleDelete, handleEdit, isAdmin, userId }) => [
  {
    Header: <span className="text">Email</span>,
    accessor: 'email',
    className: 'text',
    Cell: row => {
      return (
        <div className="cell-actions-wrapper">
          <Link to="#">{row.value}</Link>
          {shouldRenderControls(isAdmin, userId, row?.original?.id) ? (
            <div className="cell-action-buttons">
              <ReactTooltip place="left" type="dark" effect="solid" />
              <Button
                className="icon edit-user-btn"
                icon={['far', 'pencil']}
                data-tip="Edit"
                onClick={() => handleEdit(row)} // eslint-disable-line
              />
              {row?.original?.id !== userId && (
                <Button
                  className="icon danger delete-user-btn"
                  icon={['far', 'trash-alt']}
                  onClick={() => handleDelete(row)} // eslint-disable-line
                  data-tip="Delete"
                />
              )}
            </div>
          ) : null}
        </div>
      );
    }
  },
  {
    Header: <span className="text">Full Name</span>,
    accessor: 'fullName',
    className: 'text'
  },
  {
    Header: <span className="text">Admin</span>,
    accessor: 'isAdmin',
    width: 70,
    className: 'text',
    Cell: row => {
      return (
        <Icon
          className={styles[row.value ? 'iconPositive' : 'iconNegative']}
          name={['fas', row.value ? 'check-circle' : 'minus']}
        />
      );
    }
  },
  {
    Header: <span className={styles.createdText}>Created</span>,
    accessor: ({ created }) => format(created, 'HH:mm:ss   DD/MM/YYYY'),
    id: 'created',
    className: 'text'
  }
];

const shouldRenderControls = (isAdmin, userId, currentUserId) => {
  if (isAdmin || userId === currentUserId) return true;
  return false;
};
